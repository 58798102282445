<p-table [value]="fundcodesHistory"
         dataKey="id"
         [tableStyle]="{ 'min-width': '5rem' }"
         [scrollable]="true"
         scrollHeight="400px">

  <ng-template pTemplate="header">
    <tr>
      <th style="width: 1rem"></th>
      <th>Campaign</th>
      <th>Cycle</th>
      <th>Total</th>
      <th>Remaining</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-fundcodeHistoryItem let-expanded="expanded">
    <tr>
      <td>
        <p-button type="button" pRipple [pRowToggler]="fundcodeHistoryItem" [text]="true" [rounded]="true" [plain]="true" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'" />
      </td>
      <td>{{ fundcodeHistoryItem.campaignName }}</td>
      <td>{{ fundcodeHistoryItem.fundcode }}</td>
      <td>{{ fundcodeHistoryItem.amount | currency: 'USD' }}</td>
      <td>
        <span [ngStyle]="fundcodeHistoryItem.remaining < 0 ? { 'color': 'red' } : {}">{{ fundcodeHistoryItem.remainingAmount | currency: 'USD' }}</span>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="5" class="text-center">No data available</td>
    </tr>
  </ng-template>
</p-table>

<div class="text-right mt-3" *ngIf="displayOneModalPopup">
  <p-button label="Close"  class="p-button-primary" (click)="onClose()"></p-button>
</div>
