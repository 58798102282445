<div class="grid mb-5">
    <div class="col-12 sm:col-6 align-items-center">
        <p-inputGroup>
            <!-- *ngIf= "!isExpense()"  -->
            <rc-input 
                id="searchQuickfind" 
                type="text" 
                [classes]="['input-quickfind']"
                placeholder="Quickfind Search" 
                [control]="filterValue"
            /> 
            <button type="button" pButton icon="pi pi-search" class="btn-quickfind" (click)="onPageSearch()"></button>
        </p-inputGroup>
    </div>
    <div class="col-12 sm:col-6 align-items-center sm:text-right">
        <rc-generic-button 
            *ngIf= "showInDialog || isDonor()" 
            label="Add New Donor"
            id="button-add-donor"
            borderStyle="outline"
            (click)="onAddDonor()"/>
    </div>
</div>

<p-card>
    <div id="search-column-toggle" class="flex vertical">
        <button class="btn-toggle-expand {{ isExpanded ? 'icon-minimize' : 'icon-expand' }}" (click)="toggleSelectedLabels()">
        </button>
        <p-multiSelect 
            [group]="true" 
            [options]="groupedOptions" 
            [(ngModel)]="selectedColumns"
            class="multi-select-columns expand-icon"
            scrollHeight="300px" 
            optionLabel="displayColumn" 
            optionGroupLabel="label"
            optionGroupChildren="items"
            display="chip" 
            [maxSelectedLabels]="maxSelectedLabels" 
            [selectedItemsLabel]="'{0} items selected'"
            placeholder="Choose Columns" 
            id="multi-select-columns" 
            [ngClass]="{'expanded': isExpanded, 'collapsed': !isExpanded}">
        </p-multiSelect>
    </div>

    <!-- {{selectedColumns | json}} -->

    <div class="search-actions grid py-2">
        <div class="col text-left">
            
            <p-button 
                *ngIf= "isExpense()" 
                label="Selected" 
                (onClick)="menu2.toggle($event)" 
                icon="pi pi-angle-down" 
                iconPos="right" 
                severity="secondary"
                [disabled]="hasNoResults()"
                styleClass="mr-1 my-1"></p-button>
                
            <rc-generic-button 
                *ngIf= "isDonor()"
                label="Compare"
                id="button-compare"
                (click)="onCompareDonors()"
                level="secondary"
                iconImg="compare"
                [disabled]="hasNoResults()"
                [classes]="['mr-1 my-1']" />

            <p-button 
                label="Export" 
                icon="fa-sharp fa-light fa-download" 
                (onClick)="menu.toggle($event)" 
                id="button-export" 
                severity="secondary"
                [outlined]="true" 
                styleClass="my-1" 
                [disabled]="hasNoResults()"
                class="ml-0"></p-button>

        </div>
        <div class="top-paginator" *ngIf="!hasNoResults()">
            
            <div class="mb-3">
                <p-button 
                    type="button" 
                    icon="pi pi-chevron-left" 
                    styleClass="p-button-text mr-1 grey-text-button"
                    (onClick)="pageBack()"
                    />
                
                <p-button 
                    type="button" 
                    icon="pi pi-chevron-right" 
                    styleClass="p-button-text ml-1 grey-text-button"
                    (onClick)="pageForward()"
                    />
            </div>

        </div>
        <div class="col text-right">
            
            <!-- 
            SCROLLING SEARCH MENU ACTIVATING BUTTON -- FUTURE ADD
            <rc-generic-button 
                label=""
                id="button-filter"
                iconImg="filter"
                level="secondary"
                borderStyle="outline"
                (click)="scrollToTop()" 
                [classes]="['mr-1 my-1 white-button']" /> -->
            <rc-generic-button 
                label="Clear All"
                id="button-clear-filters"
                iconImg="clear-filter"
                borderStyle="outline"
                (click)="resetFilters()" 
                [disabled]="hasNoResults()"
                [classes]="['my-1']" />
            <rc-generic-button 
                label="Search"
                id="button-search"
                iconImg="search"
                (click)="onSearch()"
                [classes]="['my-1 ml-1']" />
        </div>
        <p-menu #menu [popup]="true" [model]="exportOptions"></p-menu>
        <p-menu #menu2 [popup]="true" [model]="selectedOptions"></p-menu>
        <!-- <div id="scrolling-search-filters" *ngIf="showScrollFilters">
        </div> -->
    </div>

    <div style="width: 100%; min-height: 22rem;">
        
        <p-table 
            #resultstable 
            [scrollable]="true" 
            selectionMode="multiple"
            [(selection)]="selectedRows"
            [exportHeader]="'customExportHeader'"
            [reorderableColumns]="true" 
            [paginator]="!hasNoResults()" 
            scrollHeight="flex" 
            [value]="rawData||[]"
            [columns]="selectedColumns" 
            [globalFilterFields]="filters"
            [rows]="pagingModel.maxResults"
            [sortField]="pagingModel.sortField" 
            [sortOrder]="pagingModel.sortAsc" 
            [rowsPerPageOptions]="[5, 10, 20, 100]"
            (onPage)="pageChange($event)" 
            id="table-data"
            styleClass="rc-datatable-outlined">
    
            <ng-template pTemplate="caption" style="min-height: 300px">
            </ng-template>

            <ng-template pTemplate="header" let-columns id="header-filters">
                <tr>
                    <th pFrozenColumn>
                    </th>
                    <th *ngFor="let col of columns" pReorderableColumn style="vertical-align: top;" nowrap
                        pSortableColumn={{col.columnName}} id="column-{{col.columnName}}">
                        <div class="sortable-title">
                            <div class="column-title">{{col.displayColumn}}</div>
                            <p-sortIcon field={{col.columnName}} style="cursor: pointer;" id="sort-icon-{{col.columnName}}" />
                        </div>
                    </th>
                    <th alignFrozen="right" pFrozenColumn [frozen]="balanceFrozen">
                    </th>
                </tr>
                <tr>
                    <th pFrozenColumn>
                        <p-tableCheckbox  *ngIf="!isDonor()" id="checkbox-select-all"></p-tableCheckbox>
                    </th>
                    <th *ngFor="let col of columns">
                        <div *ngIf="col.displayColumnType && col.displayColumnType.columnType === 'StatesMultiSelect'">
                            <p-columnFilter field="State" matchMode="in" [showMenu]="false">
                                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                    <p-multiSelect  [(ngModel)]="selectedStates"  [options]="states"  placeholder="Select States" (onChange)="filter($event.value)" optionValue="value" 
                                        optionLabel="value" [maxSelectedLabels]="1" appendTo="body"  [displaySelectedLabel]="true" emptyMessage = "No states found"  [selectedItemsLabel]="'{0} items selected'">
                                            <ng-template let-option pTemplate="item"> 
                                                <div class="inline-block vertical-align-middle">
                                                    <span class="ml-1 mt-1">
                                                        {{ option.value }}
                                                    </span>
                                                </div>
                                            </ng-template>
                                    </p-multiSelect>
                                </ng-template>
                            </p-columnFilter>
                        </div>
                        <div *ngIf="col.displayColumnType && col.displayColumnType.columnType === 'FundCodesMultiSelect'">
                            <p-multiSelect [options]="fundCodes" optionLabel="value" optionValue="key" [(ngModel)]="selectedFundCodes" placeholder="Select FundCodes" [maxSelectedLabels]="1"  appendTo="body" [displaySelectedLabel]="true" emptyMessage = "No FundCodes found"  [selectedItemsLabel]="'{0} items selected'"></p-multiSelect>
                        </div>
                        <div *ngIf="col.displayColumnType && col.displayColumnType.columnType === 'StatusMultiSelect'">
                            <p-multiSelect [options]="statuses" optionLabel="value" optionValue="key" [(ngModel)]="selectedStatus" placeholder="All" [maxSelectedLabels]="1"  appendTo="body" [displaySelectedLabel]="true" emptyMessage = "No Status found"  [selectedItemsLabel]="'{0} items selected'"></p-multiSelect>
                        </div>
                        <div *ngIf="col.displayColumnType && col.displayColumnType.columnType === 'ApprovalMultiSelect'">
                            <p-multiSelect [options]="approvals" optionLabel="value" optionValue="key" [(ngModel)]="selectedApproval" placeholder="All" [maxSelectedLabels]="1"  appendTo="body" [displaySelectedLabel]="true" emptyMessage = "No Approvals found"  [selectedItemsLabel]="'{0} items selected'"></p-multiSelect>
                        </div>                       
                        <div *ngIf="col.displayColumnType && (col.displayColumnType.columnType === 'TextFilter')">
                            <p-columnFilter type="text" [matchMode]="getMatchMode(col.displayColumnType.columnType)"  [matchModeOptions]="defaultTextFilter"  field={{col.columnName}} placeholder="All" ariaLabel="Filter Name" id="filter-{{col.columnName}}"/>
                        </div>
                        <div *ngIf="col.displayColumnType && (col.displayColumnType.columnType === 'CommonFilter')">
                            <p-columnFilter type="text" [matchMode]="getMatchMode(col.displayColumnType.columnType)" [matchModeOptions]="defaultCmmonFilters"  field={{col.columnName}} placeholder="All" ariaLabel="Filter Name" id="filter-{{col.columnName}}"/>
                        </div>
                        <div *ngIf="col.displayColumnType && (col.displayColumnType.columnType === 'IDFilter')">
                            <p-columnFilter type="text" [matchMode]="getMatchMode(col.displayColumnType.columnType)" (keypress)="restrictNonNumeric($event)" [matchModeOptions]="defaultTextFilter"  field={{col.columnName}} placeholder="All" ariaLabel="Filter Name" id="filter-{{col.columnName}}"/>
                        </div>
                        <div *ngIf="col.displayColumnType && (col.displayColumnType.columnType === 'DateFilter')">
                            <p-columnFilter type="date" field="date" field={{col.columnName}} placeholder="All" ariaLabel="Filter Name" id="filter-{{col.columnName}}"/>
                        </div>
                        <div *ngIf="col.displayColumnType && (col.displayColumnType.columnType === 'AmountFilter')">
                            <p-columnFilter type="text" (keypress)="restrictNonNumeric($event)" field={{col.columnName}} [matchMode]="getMatchMode(col.displayColumnType.columnType)" [matchModeOptions]="defaultAmountFilter" placeholder="All" ariaLabel="Filter Name" id="filter-{{col.columnName}}"/>
                        </div>
                    </th>
                    <th alignFrozen="right" pFrozenColumn [frozen]="balanceFrozen">
                    </th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex">
                <tr [ngClass]="isDisabledCheckbox(rowData) ? 'disabled-row' : ''">
                    <td pFrozenColumn>
                        <p-tableCheckbox id="checkbox-row-{{index}}" [value]="rowData" [disabled]="isDisabledCheckbox(rowData)"></p-tableCheckbox>
                    </td>
                    <td class="text-center" *ngFor="let col of columns" nowrap>
                       
                        <span *ngIf="col.columnName === 'DonorID'">
                            <a *ngIf="searchKeyword && searchKeyword.trim() !== ''" href="javascript:void(0)" (click)= "openDialog( rowData[col.columnName])"> 
                               {{ rowData[col.columnName] }}
                            </a>
                            <a *ngIf="!( searchKeyword && searchKeyword.trim() !=='')" [routerLink]="['/vortex/donors/card', rowData[col.columnName]]">
                               {{ rowData[col.columnName] }}
                            </a>
                        </span>
                        
                        <span *ngIf="col.columnName === 'ExpenseRequestId' || col.columnName === 'FileName'">
                            <a>{{ rowData[col.columnName] }}</a>
                        </span>
                        <span *ngIf="col.columnName==='ExpenseStatus'"><p-tag 
                            [severity]="getSeverityAndValue(rowData[col.columnName]).severity" 
                            [value]="getSeverityAndValue(rowData[col.columnName]).value">
                          </p-tag>
                        </span>
                        <span *ngIf="col.columnName==='MyApproval'">
                            <p-button  icon="pi pi-users" severity="secondary" (click)="getApprovalById(rowData['ExpenseRequestId'], $event, my)"> </p-button>
                            <p-overlayPanel #my>
                                <div *ngIf="!showLoading && approvalsData.length > 0">
                                    <div *ngFor="let approval of approvalsData">
                                        <p>
                                            {{ approval.approvedBy }} 
                                            <span *ngIf="approval.approvedDate">
                                                ({{ approval.approvedDate | date: 'short' }})
                                            </span>
                                            <span *ngIf="!approval.approvedDate">
                                            </span>
                                        </p>
                                    </div>
                                </div>
                                <div *ngIf="!showLoading && approvalsData.length === 0">
                                    <p>No approvals found.</p>
                                </div>
                            </p-overlayPanel>
                        </span>
                        <span *ngIf="col.columnName==='ExpenseAmount' || col.columnName==='DonationsAmount'">
                            {{rowData[col.columnName]}}
                            <p-button  icon="fa-sharp fa-light fa-money-check-dollar"  severity="secondary" (click)="cb.toggle($event)"> </p-button>
                            <p-overlayPanel #cb> <div class="flex align-items-center"> <span><b>{{rowData["GLAccount"]}} <br> {{rowData["GLDescription"]}}</b></span></div></p-overlayPanel>
                        </span>
                        <span *ngIf="!['DonorID', 'ExpenseRequestId', 'FileName', 'ExpenseStatus','MyApproval','ExpenseAmount'].includes(col.columnName)">
                            <span *ngIf="col.columnName.includes('Date')">
                                {{ rowData[col.columnName] ? (rowData[col.columnName] | date: 'yyyy-MM-dd') : '-' }}
                              </span>
                              <span *ngIf="!col.columnName.includes('Date')">
                                {{ rowData[col.columnName] || '-' }}
                              </span>
                        </span>
                    </td>
                    <td alignFrozen="right" pFrozenColumn [frozen]="balanceFrozen" style="white-space: nowrap; padding: 1rem 0.5rem;">
                        <rc-generic-button *ngIf="isDonor()"
                            label="View Donation History"
                            iconImg="search-money"
                            iconColor="redwood-light"
                            (click)="showDonorGivingHistory(rowData)"
                            [isIconOnly]="true"
                            [classes]="['mx-1']"
                            />
                        <rc-generic-button *ngIf="isDonor()"
                            label="Edit"
                            iconImg="cog"
                            iconColor="teal-light"
                            [isIconOnly]="true"
                            />
    
                        <rc-generic-button *ngIf="isExpense()"
                            label="My approval - submitted"
                            iconImg="user-clock"
                            iconColor="teal-dark"
                            [isIconOnly]="true"
                            [classes]="['mx-1']"
                            />
                        <rc-generic-button *ngIf="isExpense()"
                            label="RC approval - submitted"
                            iconImg="money-check"
                            iconColor="teal-dark"
                            [isIconOnly]="true"
                            [classes]="['mx-1']"
                            />
                        <rc-generic-button *ngIf="isExpense()"
                            label="Delete"
                            iconImg="trash"
                            iconColor="red-light"
                            [isIconOnly]="true"
                            [classes]="['mx-1']"
                            />
                    </td>
                </tr>
            </ng-template>
            
            <ng-template pTemplate="emptymessage">
                <tr *ngIf="searchClicked && resultstable.filteredValue?.length === 0">
                <td [attr.colspan]="selectedColumns.length + 2" class="text-center no-data-message">
                    No data found for the selected criteria.
                </td>
                </tr>
            </ng-template>
            
        </p-table>

        <div ngClass="medium loader-wrapper" *ngIf="showLoading">
            <rc-loading-spinner></rc-loading-spinner>
        </div>
        
        <div class="search-actions grid py-2" style="width: 101%" *ngIf="this.rawData ? this.rawData.length > 0 : 0">

        <div class="col text-left">

            <p-button
                *ngIf= "isExpense()"
                label="Selected"
                (onClick)="menu2.toggle($event)"
                icon="pi pi-angle-down"
                iconPos="right"
                severity="secondary"
                [disabled]="hasNoResults()"
                styleClass="mr-1 my-1"></p-button>

            <rc-generic-button
                *ngIf= "isDonor()"
                label="Compare"
                id="button-compare"
                iconImg="compare"
                level="secondary"
                [disabled]="hasNoResults()"
                [classes]="['mr-1 my-1']" />

            <p-button
                [disabled]="hasNoResults()"
                label="Export"
                icon="fa-sharp fa-light fa-download"
                (onClick)="menu.toggle($event)"
                id="button-export"
                [outlined]="true"
                styleClass="my-1"
                severity="secondary"
                class="ml-0"></p-button>

        </div>
            <p-menu #menu [popup]="true" [model]="exportOptions"></p-menu>
            <p-menu #menu2 [popup]="true" [model]="selectedOptions"></p-menu>
    
    
            <!-- <div id="scrolling-search-filters" *ngIf="showScrollFilters">
                    
            </div> -->
    
        </div>
    </div>
   
</p-card>

<div class="mb-6 pb-6" *ngIf="showInDialog"></div>

<div class="imported-dialog-footer" *ngIf="showInDialog">

    <rc-generic-button label="Cancel" borderStyle="text-only" (click)="onCancel()"> </rc-generic-button>

  <button pButton *ngIf="!hideSearchControls"
          label="Select Donor"
          [disabled]="!isSingleDonorSelected()"
          class="ml-1"
          (click)="onSelectDonor()"></button>
</div>

<p-toast></p-toast>

<p-dialog [(visible)]="showDialog"
          [modal]="true"
          [maximizable]="true"
          styleClass="p-dialog-static p-dialog-lg fadeinright animation-duration-300 global-quicksearch-dialog">
    <app-donor-card [fromSearchDonorID]="selectedDonorId" [isDockable]="false"></app-donor-card>
    <ng-template pTemplate="footer">
        <rc-generic-button label="Close" borderStyle="text-only" (click)="showDialog = false"> </rc-generic-button>
      </ng-template>
</p-dialog>
