import { Component, OnInit, ViewChild, CUSTOM_ELEMENTS_SCHEMA, Output, Input, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Stepper, StepperModule } from 'primeng/stepper';
import { CardModule } from 'primeng/card';
import { ButtonModule } from 'primeng/button';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DonorAddService } from '../../services/donor-add.service';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { MenuModule } from 'primeng/menu';
import {
  DonorTypes,
  AddressTypes,
  States,
  RelationshipTypes,
  DonorFlags,
  DonorFlag,
  DonorFormModel,
  Address,
  Email,
  PhoneNumber,
  RelationshipType,
  EmailTypes,
  PhoneTypes,
  Person
} from '../../models/DonorModel';
import { AutoCompleteModule, AutoCompleteSelectEvent } from 'primeng/autocomplete';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { ListboxModule } from 'primeng/listbox';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { MessagesModule } from 'primeng/messages';
import { GenericButtonComponent } from "../../../global/component/generic/generic-button/generic-button.component";
import { ToggleButtonModule } from 'primeng/togglebutton';
import { MainModel } from "../../../global/model/MainModel";
import { debounceTime, switchMap } from 'rxjs';
import { GenericTextInputComponent } from '../../../global/component/generic/generic-text-input/generic-text-input.component';
import { SearchService } from '../../../global/services/data/SearchService/searchservice';

@Component({
  selector: 'app-donor-add',
  standalone: true,
  templateUrl: './donor-add.component.html',
  styleUrls: ['./donor-add.component.scss'],
  imports: [
    MenuModule,
    CommonModule,
    CardModule,
    FormsModule,
    ReactiveFormsModule,
    InputTextModule,
    MultiSelectModule,
    StepperModule,
    ButtonModule,
    InputSwitchModule,
    ToastModule,
    ConfirmDialogModule,
    CheckboxModule,
    RadioButtonModule,
    DropdownModule,
    ProgressSpinnerModule,
    CalendarModule,
    AutoCompleteModule,
    DialogModule,
    TableModule,
    ListboxModule,
    TabViewModule,
    MessagesModule,
    ToggleButtonModule,
    GenericButtonComponent,
    GenericTextInputComponent
  ],
  providers: [ConfirmationService, MessageService, DonorAddService],
})
export class DonorAddComponent implements OnChanges {
  @ViewChild('addDonorStepper') addDonorStepper!: Stepper;
  @Input() isModalPopup: boolean = false;
  @Output() nextCallback = new EventEmitter<void>();

  isGIhasErrors: boolean = false;
  isContactshasErrors: boolean = false;
  isRelationshipHasErrors: boolean = false;
  isAddressFieldsMandate: boolean = false;
  isEditAddressFieldsMandate: boolean = false;

  activeIndex: number = 0; 
  form: FormGroup;
  editAddressForm: FormGroup;
  editRelationshipTypesForm: FormGroup;
  editPhoneNumberForm: FormGroup;
  editEmailForm: FormGroup;
  editSaveDonorFlag: FormGroup;
  editSaveRelationshipType: FormGroup;
  checked: boolean = true;
  unchecked: boolean = false;
  isEmployed: string = 'yes';
  showDialog: boolean = false;
  duplicateRelationshipType: boolean = false;
  inValidLastName: boolean = false;
  inValidFirstName: boolean = false;

  editAddressDialog: boolean = false;
  editRelationshipTypeDialog: boolean = false;
  editEmailDialog: boolean = false;
  editDialog: boolean = false;
  edititem: any | null = null;
  editIndex: number | null = null;
  dialogType: string | null = null;
  @Input() donorToEdit: DonorFormModel | null = null;
  @Input() isEditMode = false;
  @Input() activeStep: number = 0;
  @Output() closeEditModal = new EventEmitter<string>();

  employmentStatuses = [
    { name: 'Retired', value: 'Retired' },
    { name: 'Student', value: 'Student' },
    { name: 'Unemployed', value: 'Unemployed' },
  ];
  campaignId: any | null;

  emailTypes: EmailTypes[] = [];
  filteredEmailTypes: EmailTypes[] = [];
  editFilteredEmailTypes: EmailTypes[] = [];

  phoneTypes: PhoneTypes[] = [];
  filteredPhoneTypes: PhoneTypes[] = [];
  editFilteredPhoneTypes: PhoneTypes[] = [];

  filteredFirstNames: Person[] = [];
  filteredLastNames: Person[] = [];
  filteredDialogFirstNames: Person[] = [];
  filteredDialogLastNames: Person[] = [];
  donorTypes: DonorTypes[] = [];
  selectedDonorType: DonorTypes | null = null;

  addressTypes: AddressTypes[] = [];
  filteredAddressTypes: AddressTypes[] = [];
  editFilteredAddressTypes: AddressTypes[] = [];

  states: States[] = [];
  relationshipTypeOptions: RelationshipTypes[] = [];
  filteredRelationshipTypeOptions: RelationshipTypes[] = [];

  donorFlagOptions: DonorFlags[] = [];
  relationshipType: string = '';
  savedAddresses: Address[] = [];
  savedPhoneNumbers: PhoneNumber[] = [];
  savedEmails: Email[] = [];
  savedDonorFlags: DonorFlags[] = [];
  filteredDonorFlags: DonorFlags[] = [];
  savedRelationships: RelationshipType[] = [];

  labelClassR: string = 'field-label required';
  labelClassNR: string = 'field-label';

  addNewDonorFromRelationship: FormGroup;
  showAddNewDonorDialog: boolean = false;

  constructor(private fb: FormBuilder,
    private donorAddService: DonorAddService,
    private searchService: SearchService,
    protected mainModel: MainModel,
    private confirmationService: ConfirmationService,
    private messageService: MessageService) {
    this.form = this.getDonorForm({});

    this.editSaveDonorFlag = this.fb.group({
      title: ['', Validators.required],
      year: ['', Validators.required],
      description: ['', Validators.required],
    });

    this.editSaveRelationshipType = this.fb.group({
      relationshipType: ['', Validators.required],
    });

    this.addNewDonorFromRelationship = this.fb.group({
      firstName: ['', [this.validateName]],
      lastName: ['', [ this.validateName]],
      isSaveAddressSelected: [false]
    });

    this.editAddressForm = this.fb.group({
      addressTypeId: [''],
      addressLine1: [''],
      addressLine2: [''],
      city: [''],
      stateId: [''],
      zipcode: [''],
      county: [''],
      isPrimary: [false]
    });

    this.editPhoneNumberForm = this.fb.group({
      phoneType: [''],
      phoneNumber: ['', Validators.pattern(/^\(\d{3}\) \d{3}-\d{4}$/)]
    });

    this.editEmailForm = this.fb.group({
      type: [''],
      email: ['', [Validators.email]]
    });

    this.editRelationshipTypesForm = this.fb.group({
      relationshipTypeId: [''],
      firstName: [''],
      lastName: [''],
      relatedDonorId:[''],
      isHousehold: [true],
    });

    this.campaignId = 81;
    //#region "Bindings"

    this.form.get('donorTypeId')?.valueChanges.subscribe((value) => {
      this.giReset();
      this.form.get('prefix')?.setErrors(null);
      this.form.get('firstName')?.setErrors(null);
      this.form.get('middleName')?.setErrors(null);
      this.form.get('lastName')?.setErrors(null);

      if (value !== '7') {
        this.labelClassNR= 'field-label required';
      }
      else{
        this.labelClassNR = 'field-label';
      }

      if (value === '7') {
        this.labelClassR = 'field-label required';
      }
      else {
        this.labelClassR = 'field-label';
      }
      
      this.form.get('organization')?.setValue('');
      this.form.get('organization')?.setErrors(null);
      this.isGIhasErrors= false;
    });

    this.form.get('currentlyEmployed')?.valueChanges.subscribe((value) => {
      if (value === 'yes') {
        this.form.get('employmentStatus')?.setValue('');
      }
      else if (value === 'no') {
        this.form.get('employer')?.setValue('');
        this.form.get('occupation')?.setValue('');
      }
      this.isEmployed = value;
    });

    // this.donorAddService.getDonorTypes(parseInt(this.campaignId)).subscribe((data: any) => {
    //   this.donorTypes = data;
    //   if (this.donorToEdit?.donorTypeId)
    //     this.loadSelectedDonor(this.donorToEdit.donorTypeId);
    // });

    this.donorAddService.getStates(parseInt(this.campaignId)).subscribe((data: any) => {
      this.states = data;
    });

    this.donorAddService.getPhoneTypes(parseInt(this.campaignId)).subscribe((data: any) => {
      this.phoneTypes = data;
      this.filteredPhoneTypes = data;
      this.editFilteredPhoneTypes = data;

      if(this.filteredPhoneTypes && this.filteredPhoneTypes.length > 0) {
        this.form.get('phoneNumber.phoneType')?.setValue(this.filteredPhoneTypes[0].key);
      }

    });

    this.donorAddService.getEmailTypes(parseInt(this.campaignId)).subscribe((data: any) => {
      this.emailTypes = data;
      this.filteredEmailTypes = data;
      this.editFilteredEmailTypes = data;

      if(this.filteredEmailTypes && this.filteredEmailTypes.length > 0) {
        this.form.get('email.type')?.setValue(this.filteredEmailTypes[0].key);
      }
    });

    this.donorAddService.getAddressTypes(parseInt(this.campaignId)).subscribe((data: any) => {
      this.addressTypes = data;
      this.filteredAddressTypes = data;
      this.editFilteredAddressTypes = data;

      if(this.filteredAddressTypes && this.filteredAddressTypes.length > 0) {
        this.form.get('address.addressTypeId')?.setValue(this.filteredAddressTypes[0].key);
      }
    });

    this.donorAddService.getRelationTypes(parseInt(this.campaignId)).subscribe((data: any) => {
      this.relationshipTypeOptions = data;
      this.filteredRelationshipTypeOptions = data;
    });

    this.donorAddService.getDonorFlags(parseInt(this.campaignId)).subscribe((data: any) => {
      this.donorFlagOptions = data;
    });

    //#endregion "Bindings"
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.donorAddService.getDonorTypes(parseInt(this.campaignId)).subscribe((data: any) => {
      this.donorTypes = data;
      if (this.donorToEdit?.donorTypeId)
        this.loadSelectedDonor(this.donorToEdit.donorTypeId);
      
      if (this.isEditMode && this.donorToEdit) {      
        this.populateFormForEdit(this.donorToEdit);
      }
    });
  }

  ngOnInit() {
    this.form.get('relationshipTypes.firstName')?.valueChanges
      .pipe(debounceTime(300), switchMap((q) => {
        if (q && q.length >= 3) {
          return this.donorAddService.searchByFirstName(q, this.campaignId)
        }
        return [];
      }))
      .subscribe((data: Person[]) => {
        this.filteredFirstNames = data;
      });

    this.form.get('relationshipTypes.lastName')?.valueChanges
      .pipe(debounceTime(300), switchMap((q) => {
        if (q && q.length >= 3) {
          return this.donorAddService.searchByLastName(q, this.campaignId)
        }
        return [];
      }))
      .subscribe((data: Person[]) => {
        this.filteredLastNames = data;
      });
      
    this.editRelationshipTypesForm.get('firstName')?.valueChanges
      .pipe(debounceTime(300), switchMap((q) => {
        if (q && q.length >= 3) {
          return this.donorAddService.searchByFirstName(q, this.campaignId)
        }
        return [];
      }))
      .subscribe((data: Person[]) => {
        this.filteredDialogFirstNames = data; console.debug("Filtered First Names:", this.filteredDialogFirstNames);
      });

    this.editRelationshipTypesForm.get('lastName')?.valueChanges
      .pipe(debounceTime(300), switchMap((q) => {
        if (q && q.length >= 3) {
          return this.donorAddService.searchByLastName(q, this.campaignId)
        }
        return [];
      }))
      .subscribe((data: Person[]) => {
        this.filteredDialogLastNames = data; console.debug("Filtered First Names:", this.filteredDialogLastNames);
      });

      this.form.get('phoneNumber.phoneNumber')?.valueChanges.subscribe(()=>{
      this.form.get('phoneNumber.phoneNumber')?.updateValueAndValidity();
    });
  }

  populateFormForEdit(donor: DonorFormModel) {

    this.form = this.getDonorForm(donor);

    if (donor.donorTypeId)
      this.loadSelectedDonor(donor.donorTypeId);

    // Address
    this.savedAddresses = donor.addresses || [];
    // Phone numbers
    this.savedPhoneNumbers = donor.phoneNumbers || [];
    // Eamils
    this.savedEmails = donor.emails || [];
    // Relationships
    this.savedRelationships = donor.relationshipTypes || [];
    // donorFlags
    this.savedDonorFlags = donor.existingDonorFlags || [];
  }

  getDonorForm(donor: DonorFormModel) {
    return this.fb.group({
      donorId: [donor.donorId || ''],
      campaignId: [donor.campaignId || ''],
      
      donorTypeId: [donor.donorTypeId || ''],
      selectedDonorType: new FormControl<DonorTypes | null>(this.selectedDonorType),
      prefix: [donor.prefix || '', [this.validateNameButAllowEmpty]],
      firstName: [donor.firstName || '', [this.validateName]],
      middleName: [donor.middleName || '', [this.validateNameButAllowEmpty]],
      lastName: [donor.lastName || '', [this.validateName]],
      suffix: [donor.suffix || ''],
      currentlyEmployed: [donor.currentlyEmployed || 'yes'],
      employeeType: [donor.employeeType || ''],
      employmentStatus: [donor.employmentStatus || ''],
      employer: [donor.employer || ''],
      occupation: [donor.occupation || ''],
      
      legacyId: [donor.legacyId || '', Validators.pattern('^[0-9]+$')],
      fECCommitteeID: [donor.fecCommitteeId || ''],
      organization: [donor.organization || ''],
      dateOfBirth: [donor.dateOfBirth || ''],
      candidateId: [donor.candidateId || ''],

      //Contacts
      address: this.fb.group({
        addressTypeId: [''],
        addressLine1: [''],
        addressLine2: [''],
        city: [''],
        stateId: [''],
        zipcode: [''],
        county: [''],
        isPrimary: [false]
      }),

      phoneNumber: this.fb.group({
        phoneType: [''],
        phoneNumber: ['', [Validators.pattern(/^\(\d{3}\) \d{3}-\d{4}$/)]]

      }),

      email: this.fb.group({
        type: [''],
        email: ['', [Validators.email]]
      }),

      //Relationships
      relationshipTypes: this.fb.group({
        relationshipTypeId: [''],
        firstName: [''],
        lastName: [''],
        relatedDonorId: [''],
        isHousehold: [true],
      }),

      informalName: [donor.informalName || ''],
      formalSalutation: [donor.formalSalutation || ''],
      mailSalutation: [donor.mailSalutation || ''],

      //Additional details
      donorFlagType: [''],
      doNotCall: [donor.doNotCall || false],
      doNotEmail: [donor.doNotEmail || false],
      doNotMail: [donor.doNotMail || false],
      doNotSolicit: [donor.doNotSolicit || false],
      isDeceased: [donor.isDeceased || false],
    });
  }
  //#region "address"

  isAddAddressEnabled(): boolean {
    const addressGroup = this.form.get('address') as FormGroup;

    const addressTypeId = addressGroup.get('addressTypeId')?.value;
    const addressLine1 = addressGroup.get('addressLine1')?.value?.trim();
    const addressLine2 = addressGroup.get('addressLine2')?.value?.trim();
    const city = addressGroup.get('city')?.value?.trim();
    const stateId = addressGroup.get('stateId')?.value;
    const zipcode = addressGroup.get('zipcode')?.value?.trim();
    const county = addressGroup.get('county')?.value?.trim();

    return !(addressTypeId && (addressLine1 || addressLine2 || stateId || city || county || zipcode));
  }

  addAddress(): void {
    if (this.isAddAddressEnabled()){
      this.isAddressFieldsMandate= true;
    }
    else{
      this.isAddressFieldsMandate= false;
      const addr = this.form.get('address')?.value;
      if (addr.isPrimary) {
        this.savedAddresses.forEach((addr) => (addr.isPrimary = false));
      }
      const address: Address = {
        addressTypeId: addr.addressTypeId || '',
        addressLine1: addr.addressLine1 || '',
        addressLine2: addr.addressLine2 || '',
        city: addr.city || '',
        stateId: addr.stateId || '',
        zipcode: addr.zipcode || '',
        county: addr.county || '',
        isPrimary: addr.isPrimary || false
      }
      this.savedAddresses.push(address);
      this.updateFilteredAddresses();
      this.clearAddress();
    }
  }

  private updateFilteredAddresses() {
    const usedTypes = this.savedAddresses.map(a => a.addressTypeId);
    this.filteredAddressTypes = this.addressTypes.filter(t => !usedTypes.includes(t.key));
    this.editFilteredAddressTypes = this.addressTypes.filter(t => !usedTypes.includes(t.key));
  }

  clearAddress() {
    this.form.get('address')?.reset();
    if(this.filteredAddressTypes && this.filteredAddressTypes.length > 0) {
      this.form.get('address.addressTypeId')?.setValue(this.filteredAddressTypes[0].key);
    }
  }

  editAddress(address: Address): void {
    this.dialogType = 'address';
    this.edititem = { ...address };
    this.editIndex = this.savedAddresses.findIndex(x => x == address);
    this.editAddressForm.patchValue(this.edititem);
    this.editDialog = true;

    this.editFilteredAddressTypes = this.addressTypes.filter(t => t.key === this.edititem.addressTypeId 
      || !this.savedAddresses.some(a => a.addressTypeId===t.key) );
  }

  getAddressTypeValue(key: string): string {
    const addressTypeId = this.addressTypes.find(o => o.key === key);
    return addressTypeId ? addressTypeId.value : '';
  }

  getStateValue(key: string): string {
    const stateId = this.states.find(o => o.key === key);
    return stateId ? stateId.value : '';
  }

  getFormattedAddress(address: Address): string {
    return [
      `<strong>${this.getAddressTypeValue(address.addressTypeId)} ${address.isPrimary ? '- Primary' : ''} </strong>`,
      [address.addressLine1, address.addressLine2].filter(Boolean).join(', '),
      [address.city, address.stateId ? this.getStateValue(address.stateId) : '', address.county, address.zipcode].filter(Boolean).join(', ')
    ].filter(Boolean).join('<br>');
  }
  //#endregion "Address"

  //#region "PhoneNumber"

  isAddPhoneNumberEnabled(): boolean {
    const numberGroup = this.form.get('phoneNumber') as FormGroup;
    const phoneType = numberGroup.get('phoneType')?.value;
    const phoneNumber = numberGroup.get('phoneNumber')?.value?.trim();
    const isNumberValid = numberGroup.get('phoneNumber')?.valid || phoneNumber === '';
    
    return  !(phoneType && phoneNumber && isNumberValid);
  }

  addPhoneNumber(): void {
    if (this.isAddPhoneNumberEnabled()){
        this.form.get('phoneNumber.phoneNumber')?.markAsTouched();
        this.form.get('phoneNumber.phoneNumber')?.setErrors({ required : true });
    }
    else
    {
      const numberGroup = this.form.get('phoneNumber') as FormGroup;
      const phoneNumber: PhoneNumber = {
        phoneType: numberGroup.get('phoneType')?.value || '',
        phoneNumber: numberGroup.get('phoneNumber')?.value || '',
      }
      this.savedPhoneNumbers.push(phoneNumber);
      this.updateFilteredPhoneNumbers();
      this.clearPhoneNumber();
    }
  }

  private updateFilteredPhoneNumbers() {
    const usedTypes = this.savedPhoneNumbers.map(a => a.phoneType);
    this.filteredPhoneTypes= this.phoneTypes.filter(t => !usedTypes.includes(t.key));
    this.editFilteredPhoneTypes = this.phoneTypes.filter(t => !usedTypes.includes(t.key));
  }

  clearPhoneNumber() {
    this.form.get('phoneNumber')?.reset();
    
    if(this.filteredPhoneTypes && this.filteredPhoneTypes.length > 0) {
      this.form.get('phoneNumber.phoneType')?.setValue(this.filteredPhoneTypes[0].key);
    }
  }

  editPhoneNumber(phoneNumber: PhoneNumber): void {
    this.dialogType = 'phoneNumber';
    this.edititem = { ...phoneNumber };
    this.editIndex = this.savedPhoneNumbers.findIndex(x => x == phoneNumber);
    this.editPhoneNumberForm.patchValue(this.edititem);
    this.editDialog = true;

    this.editFilteredPhoneTypes = this.phoneTypes.filter(t => t.key === this.edititem.phoneType 
      || !this.savedPhoneNumbers.some(a => a.phoneType === t.key) );
  }

  confirmDelete(event:MouseEvent, item: any, list: any[], message:string,type:string): void {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: message,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: "none",
      rejectIcon: "none",
      rejectButtonStyleClass: "p-button-text",
      accept: () => {
        list.splice(list.indexOf(item), 1);
        if(type==='address') {
          this.updateFilteredAddresses();
        }
        else if(type==='email') {
          this.updateFilteredEmails();
        }
        else if(type==='phone') {
          this.updateFilteredPhoneNumbers();
        }
        else if(type==='relationship') {
          this.updateFilteredRelationshipTypeOptions();
        }
      },
      reject: () => {
      }
    });
  }

  getPhoneTypeValue(key: string): string {
    const phoneType = this.phoneTypes.find(o => o.key === key);
    return phoneType ? phoneType.value : '';
  }

  getFormattedPhoneNumber(phoneNumber: PhoneNumber): string {
    return ` <strong>${this.getPhoneTypeValue(phoneNumber.phoneType)}</strong><br>
                     ${phoneNumber.phoneNumber}`
  }
  //#endregion "PhoneNumber"

  //#region "Email"

  isAddEmailEnabled(): boolean {
    const emailGroup = this.form.get('email') as FormGroup;
    const type = emailGroup.get('type')?.value;
    const email = emailGroup.get('email')?.value?.trim();
    const isEmailValid = emailGroup.get('email')?.valid || email === '';

    return  !(email && isEmailValid);
  }

  addEmail(): void {
    if (this.isAddEmailEnabled()) {
      this.form.get('email.email')?.markAsTouched();
      this.form.get('email.email')?.setErrors({ invalid: true });
    }
    else
    {
      const emailGroup = this.form.get('email') as FormGroup;
      const email: Email = {
        type: emailGroup.get('type')?.value || '',
        email: emailGroup.get('email')?.value || '',
      }
      this.savedEmails.push(email);
      this.updateFilteredEmails();
      this.clearEmail();
    }
  }

  private updateFilteredEmails() {
    const usedTypes = this.savedEmails.map(a => a.type);
    this.filteredEmailTypes = this.emailTypes.filter(t => !usedTypes.includes(t.key));
    this.editFilteredEmailTypes = this.emailTypes.filter(t => !usedTypes.includes(t.key));
  }

  clearEmail() {
    this.form.get('email')?.reset();
    
    if(this.filteredEmailTypes && this.filteredEmailTypes.length > 0) {
      this.form.get('email.type')?.setValue(this.filteredEmailTypes[0].key);
    }
  }

  editEmail(email: Email): void {
    this.dialogType = 'email';
    this.edititem = { ...email };
    this.editIndex = this.savedEmails.findIndex(x => x == email);
    this.editEmailForm.patchValue(this.edititem);
    this.editDialog = true;

    this.editFilteredEmailTypes = this.emailTypes.filter(t => t.key===this.edititem.type 
      || !this.savedEmails.some(a => a.type===t.key) );
  }

  getEmailTypeValue(key: string): string {
    const type = this.emailTypes.find(o => o.key === key);
    return type ? type.value : '';
  }

  getFormattedEmail(email: Email): string {
    return ` <strong>${this.getEmailTypeValue(email.type)}</strong><br>
                     ${email.email}`
  }

  //#endregion "Email"

  //#region "Validations"
  allowOnlyNumbers(e: KeyboardEvent): void {
    const charCode = e.key.charCodeAt(0);
    if (charCode < 48 || charCode > 57) {
      e.preventDefault();
    }
  }

  validateName(control: AbstractControl) {
    const name = control.value;
    if(!name){  return {required: true};}
    if(/\d/.test(name)){return {noNumbers: true};}
    if(!/^[a-zA-Z\s-'.,]+$/.test(name)){return {invalidName: true};}
    return null;
  }

  validateNameButAllowEmpty(control: AbstractControl) {
    const name = control.value;
    if(/\d/.test(name)){return {noNumbers: true};}
    if(name && !/^[a-zA-Z\s-'.,]+$/.test(name)) {return{invalidName: true}};
    return null;
  }

  stepChange = (step: number) => {
    if(step !== 0 && (this.form.get('donorTypeId')?.value.trim() === ''  || this.giValidation())) 
     this.addDonorStepper.activeStep = 0;
  }

  formatPhoneNumber(event: Event, type: string): void {
    const input = event.target as HTMLInputElement;
    let value = input.value.replace(/\D/g, '');

    if (value.length > 10) {
      value = value.substring(0, 10);
    }
    if (value.length >= 7) {
      input.value = `(${value.substring(0, 3)}) ${value.substring(3, 6)}-${value.substring(6)}`;
    } else if (value.length >= 4) {
      input.value = `(${value.substring(0, 3)}) ${value.substring(3)}`;
    } else if (value.length > 0) {
      input.value = `(${value}`;
    }

    if (type === "phoneNumber")
      this.form.get('phoneNumber.phoneNumber')?.setValue(input.value);
    else 
      this.editPhoneNumberForm.get('phoneNumber')?.setValue(input.value);

  }
  //#endregion "Validations"

  //#region "RelationshipType"
  isaddRelationshipEnabled() {
    const relationshipTypesGroup = this.form.get('relationshipTypes') as FormGroup;
    const relationshipTypeId = relationshipTypesGroup.get('relationshipTypeId')?.value?.trim();
    const firstName = relationshipTypesGroup.get('firstName')?.value?.trim();
    const lastName = relationshipTypesGroup.get('lastName')?.value?.trim();
    return !(relationshipTypeId && firstName && lastName);
  }

  addRelationship(): void {
    const relationshipTypesGroup = this.form.get('relationshipTypes') as FormGroup;
    const relationshipTypeId = relationshipTypesGroup.get('relationshipTypeId')?.value;
    const firstName = relationshipTypesGroup.get('firstName')?.value?.trim();
    const lastName = relationshipTypesGroup.get('lastName')?.value?.trim();
    let isProceed: boolean = true;

    if(this.isaddRelationshipEnabled()){
      if (!relationshipTypeId){
        this.form.get('relationshipTypes.relationshipTypeId')?.markAsTouched(); // for this we no need to clear 
        this.form.get('relationshipTypes.relationshipTypeId')?.setErrors({ required : true });
      }

      this.inValidFirstName= false;
      this.inValidLastName= false;

      if (!firstName){
        this.form.get('relationshipTypes.firstName')?.markAsTouched();
        this.form.get('relationshipTypes.firstName')?.setErrors({ required : true });
      }
      else {
        if (!this.filteredFirstNames.some(f => f.text.split(' ')[0]=== firstName.trim())) 
          this.inValidFirstName= true;
      }

      if (!lastName){
        this.form.get('relationshipTypes.lastName')?.markAsTouched();
        this.form.get('relationshipTypes.lastName')?.setErrors({ required : true });
      }
      else {
        if (!this.filteredLastNames.some(f => f.text.split(' ')[1] === lastName.trim())) 
          this.inValidLastName= true;
      }

      isProceed= false;
    }
    
    if (firstName && lastName){
      if (firstName){
        if (!this.filteredFirstNames.some(f => f.text.split(' ')[0]=== firstName.trim())) {
          this.inValidFirstName= true;
          isProceed= false;
        }
      }

      if (lastName){
        if (!this.filteredLastNames.some(f => f.text.split(' ')[1] === lastName.trim())) {
          this.inValidLastName= true;
          isProceed= false;
        }
      } 
      // if (!this.filteredFirstNames.some(f => f.text === firstName+ ' '+ lastName)) {
      //   this.inValidFirstName= true;
      //   this.inValidLastName= true;
      // }
  }
  
  if(isProceed){
      const type = this.form.get('relationshipTypes')?.value;
      const relationshipType: RelationshipType = {
        relationshipTypeId: type.relationshipTypeId || '',
        firstName: type.firstName || '',
        lastName: type.lastName || '',
        relatedDonorId: type.relatedDonorId || '',
        isHousehold: type.isHousehold || false
      }
      this.savedRelationships.push(relationshipType);
      this.updateFilteredRelationshipTypeOptions();
      this.clearRelationship();
      this.inValidFirstName= false;
      this.inValidLastName= false;
      this.isRelationshipHasErrors = false;
    }
  }

  private updateFilteredRelationshipTypeOptions() {
    const usedTypes = this.savedRelationships.map(a => a.relationshipTypeId);
    this.filteredRelationshipTypeOptions = this.relationshipTypeOptions.filter(t => !usedTypes.includes(t.value));
  }
  clearRelationship() {
    this.form.get('relationshipTypes')?.reset();
    this.form.get('relationshipTypes')?.markAsPristine();
    this.form.get('relationshipTypes')?.markAsUntouched();
    this.inValidLastName= false
    this.inValidFirstName= false
  }

  editRT(relationshipType: RelationshipType): void {
    this.dialogType = 'relationshipType';
    this.edititem = { ...relationshipType };
    this.editIndex = this.savedRelationships.findIndex(x => x == relationshipType);
    this.editRelationshipTypesForm.patchValue(this.edititem);
    this.editDialog = true;
  }

  getrelationshipTypeValue(value: string): string {
    const option = this.relationshipTypeOptions.find(o => o.value == value);
    const text = option ? option.text : null;
    return text || '';
  }

  getFormattedRT(relationshipType: RelationshipType): string {
    return ` <strong>${this.getrelationshipTypeValue(relationshipType.relationshipTypeId)}</strong>  <br>
    ${relationshipType.firstName} ${relationshipType.lastName}`
  }

  clearSaveRelationshipType() {
    this.editSaveRelationshipType.reset();
    this.showDialog = false;
  }

  clearAddNewDonorDialog() {
    this.addNewDonorFromRelationship.reset();
    this.form.get('relationshipTypes')?.reset();
    this.showAddNewDonorDialog = false;
  }

  saveNewDonor(event:MouseEvent){
    let invalid: boolean = true;
    if(this.addNewDonorFromRelationship.value.firstName === "") {
      this.addNewDonorFromRelationship.get('firstName')?.markAsTouched();
      this.addNewDonorFromRelationship.get('firstName')?.markAsDirty();
      this.addNewDonorFromRelationship.get('firstName')?.setErrors({ required : true });

      invalid = false;
    }

    if(this.addNewDonorFromRelationship.value.lastName === ""){
      this.addNewDonorFromRelationship.get('lastName')?.markAsTouched();
      this.addNewDonorFromRelationship.get('lastName')?.markAsDirty();
      this.addNewDonorFromRelationship.get('lastName')?.setErrors({ required : true });

      invalid = false;
    }

    if(invalid) {
      const donorFormModel: DonorFormModel = {
        campaignId:this.campaignId,
        donorTypeId: 7,
        firstName: this.addNewDonorFromRelationship.value.firstName,
        middleName: '',
        lastName: this.addNewDonorFromRelationship.value.lastName,
        prefix:'',
        suffix:  '',
        currentlyEmployed:false,
        employeeType: '',
        employmentStatus:  '',
        employer:  '',
        occupation:  '',
        fecCommitteeId:  '',
        organization: '',
        donorId:  '',
        legacyId: 0 ,
        candidateId:  '',
        dateOfBirth:  '',

        addresses: this.addNewDonorFromRelationship.value.isSaveAddressSelected === true? this.savedAddresses : [],
        phoneNumbers: [],
        emails: [],
        relationshipTypes: [],
        informalName: '',
        formalSalutation:  '',
        mailSalutation:  '',

        donorFlagIds: [],
        doNotCall: false,
        doNotEmail:  false,
        doNotMail:  false,
        doNotSolicit:  false,
        isDeceased: false
      };

      this.confirmationService.confirm({
        target: event.target as EventTarget,
        message: 'Are you sure you want to save this new donor?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: "none",
        rejectIcon: "none",
        rejectButtonStyleClass: "p-button-text",
        accept: () => {
          this.donorAddService.saveDonor(donorFormModel).subscribe({
            next: (res) => {
              this.messageService.add({ severity: 'success', summary: 'Success', detail: 'The donor has been saved successfully.' });
              this.clearAddNewDonorDialog();
            },
            error: (error) => {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to save donor information.\n Please review the details and try again!' });
            },
          });
        },
        reject: () => {
        }
      });
    }
  }

  saveNewRelationship() {
    if (this.editSaveRelationshipType.value.relationshipType === ''){
      this.editSaveRelationshipType.get('relationshipType')?.setErrors({ invalid: true });
    }
    else {
      this.duplicateRelationshipType= false;
      if (this.editSaveRelationshipType.valid) {
        this.relationshipType = this.editSaveRelationshipType.value.relationshipType || '';
        if (this.relationshipType) {

          if (this.relationshipTypeOptions.some(f => f.text === this.relationshipType.trim())) {
            this.duplicateRelationshipType= true;
          }
          else {
            this.donorAddService.addRelationType(this.relationshipType, this.campaignId).subscribe({
              next: (response) => {
                this.showDialog = false;
                this.donorAddService.getRelationTypes(this.campaignId).subscribe((data: any) => {
                  this.relationshipTypeOptions = data;
                  this.updateFilteredRelationshipTypeOptions();
                  const match = this.relationshipTypeOptions.find(r=> r.text=== this.relationshipType)
                  this.form.get('relationshipTypes.relationshipTypeId')?.setValue(match?.value);
                  this.relationshipType = '';
                });
              },
              error: (error) => {
              },
            });
          }
        }
      }
    }
  }

  onFirstNameSelect(event: AutoCompleteSelectEvent) {
    const selectedFN: Person = event.value;
    const fullName = selectedFN.text.split(' ');
    this.form.get('relationshipTypes.firstName')?.setValue(fullName[0]);
    this.form.get('relationshipTypes.lastName')?.setValue(fullName.slice(1).join(''));
    this.form.get('relationshipTypes.relatedDonorId')?.setValue(selectedFN.value);
    this.inValidFirstName= false;
  }

  onLastNameSelect(event: AutoCompleteSelectEvent) {
    const selectedLN: Person = event.value;
    const fullName = selectedLN.text.split(' ');
    this.form.get('relationshipTypes.firstName')?.setValue(fullName[0]);
    this.form.get('relationshipTypes.lastName')?.setValue(fullName.slice(1).join(''));
    this.form.get('relationshipTypes.relatedDonorId')?.setValue(selectedLN.value);
    this.inValidLastName= false;
  }

  //edit pop up logic
  onDialogFirstNameSelect(event: AutoCompleteSelectEvent) {
    console.debug('Selected First Name:', event);
    const selectedFN: Person = event.value;
    const fullName = selectedFN.text.split(' ');
    this.editRelationshipTypesForm.get('firstName')?.setValue(fullName[0]);
    this.editRelationshipTypesForm.get('lastName')?.setValue(fullName.slice(1).join(''));
    this.editRelationshipTypesForm.get('relatedDonorId')?.setValue(selectedFN.value);
  }

  onDialogLastNameSelect(event: AutoCompleteSelectEvent) {
    console.debug('Selected last Name:', event);
    const selectedLN: Person = event.value;
    const fullName = selectedLN.text.split(' ');
    this.editRelationshipTypesForm.get('firstName')?.setValue(fullName[0]);
    this.editRelationshipTypesForm.get('lastName')?.setValue(fullName.slice(1).join(''));
    this.editRelationshipTypesForm.get('relatedDonorId')?.setValue(selectedLN.value);
  }
  //#endregion "RelationshipType"

  //#region "Donor flags"
  addDonorFlag(): void {
    const dfType = this.form.get('donorFlagType')?.value;
    if (dfType && this.donorFlagOptions.find((f) => f.key === dfType.key)) {
      this.savedDonorFlags.push({ ...dfType });
    }
    this.clearDonorFlag();
  }

  clearDonorFlag(): void {
    this.form.get('donorFlagType')?.reset();
  }

  filterDonorFlags(event: any) {
    const query = event.query.toLowerCase();
    this.filteredDonorFlags = this.donorFlagOptions.filter((f) =>
      f.value.toLowerCase().includes(query)
    );

    const usedTypes = this.savedDonorFlags.map(a => a.key);
    this.filteredDonorFlags = this.filteredDonorFlags.filter(t => !usedTypes.includes(t.key));
  }

  clearNewDonorFlagType() {
    this.editSaveDonorFlag.reset();
    this.showDialog = false;
  }

  saveNewDonorFlagType() {
    if (this.editSaveDonorFlag.valid) {

      const flag: DonorFlag = {
        year: parseInt(this.editSaveDonorFlag.value.year || '', 10),
        description: this.editSaveDonorFlag.value.description || '',
        title: this.editSaveDonorFlag.value.title || '',
      };

      this.donorAddService.addDonorFlag(flag, this.campaignId).subscribe({
        next: (response) => {
          this.showDialog = false;
          this.editSaveDonorFlag.reset();
          this.donorAddService.getDonorFlags(this.campaignId).subscribe((data: any) => {
            this.donorFlagOptions = data;
          });
        },
        error: (error) => {
          console.debug('Failed to add Donor flag');
        },
      });
    }
  }

  //#endregion "donorFlag"

  closeDialog(): void {
    if (this.isModalPopup) {
      if (this.isEditMode) {
        this.closeEditModal.emit("close");
        this.isModalPopup = false;
        this.reset();
      }
      else
        this.searchService.emitCloseDonorModal();
    } else {
      this.editDialog = false;
      this.edititem = null;
      this.dialogType = null;
      this.editIndex = null;
    }
    this.isEditAddressFieldsMandate = false
  }

  cancelDialog()
  {
    this.confirmationService.confirm({
      // target: event.target as EventTarget,
      message: "Are you sure you want to cancel? This will erase all data you have entered?",
      header: 'CancelReset form',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: "none",
      rejectIcon: "none",
      rejectButtonStyleClass: "p-button-text",
      accept: () => {
        this.reset();
        this.updateFilteredAddresses();
        this.clearAddress();
        this.updateFilteredEmails();
        this.clearEmail();
        this.updateFilteredPhoneNumbers();
        this.clearPhoneNumber();
        this.updateFilteredRelationshipTypeOptions();
        this.clearRelationship();
        this.addDonorStepper.activeStep = 0;
      },
      reject: () => {
      }
    });
  }

  isEditAddAddressEnabled(): boolean {
    const addressTypeId = this.editAddressForm.get('addressTypeId')?.value;
    const addressLine1 = this.editAddressForm.get('addressLine1')?.value?.trim();
    const addressLine2 = this.editAddressForm.get('addressLine2')?.value?.trim();
    const city = this.editAddressForm.get('city')?.value?.trim();
    const stateId = this.editAddressForm.get('stateId')?.value;
    const zipcode = this.editAddressForm.get('zipcode')?.value?.trim();
    const county = this.editAddressForm.get('county')?.value?.trim();

    return !(addressTypeId && (addressLine1 || addressLine2 || stateId || city || county || zipcode));
  }

  isEditAddEmailEnabled(): boolean {
    const type = this.editEmailForm.get('type')?.value;
    const email = this.editEmailForm.get('email')?.value?.trim();
    const isEmailValid = this.editEmailForm.get('email')?.valid || email === '';
    
    return !(email && isEmailValid);
  }

  isEditAddPhoneNumberEnabled(): boolean {
    const phoneType = this.editPhoneNumberForm.get('phoneType')?.value;
    const phoneNumber = this.editPhoneNumberForm.get('phoneNumber')?.value?.trim();
    const isNumberValid = this.editPhoneNumberForm.get('phoneNumber')?.valid || phoneNumber === '';

    return !(phoneType && phoneNumber && isNumberValid);
  }

  saveEditItem(): void {
    if (this.dialogType === 'address') {
      if (this.isEditAddAddressEnabled()){
        this.isEditAddressFieldsMandate= true;
      }
      else{
        this.isEditAddressFieldsMandate= false;
        if (this.editAddressForm.valid && this.editIndex != null) {
          if (this.editAddressForm.get('isPrimary')?.value) {
            this.savedAddresses.forEach((addr) => (addr.isPrimary = false));
          }
          this.savedAddresses[this.editIndex] = this.editAddressForm.value;
          this.updateFilteredAddresses();
        }
        this.closeDialog();
      }
    }
    else if (this.dialogType === 'phoneNumber') {
      if (this.isEditAddPhoneNumberEnabled()){
          this.editPhoneNumberForm.get('phoneNumber')?.markAsTouched();
          this.editPhoneNumberForm.get('phoneNumber')?.setErrors({ required : true });
      }
      else {
        if (this.editPhoneNumberForm.valid && this.editIndex != null) {
          this.savedPhoneNumbers[this.editIndex] = this.editPhoneNumberForm.value;
          this.updateFilteredPhoneNumbers();
        }
        this.closeDialog();
      }
    }
    else if (this.dialogType === 'email') {
      if (this.isEditAddEmailEnabled()){
        this.editEmailForm.get('email')?.markAsTouched();
        this.editEmailForm.get('email')?.setErrors({ invalid: true });
      }
      else {
        if (this.editEmailForm.valid && this.editIndex != null) {
          this.savedEmails[this.editIndex] = this.editEmailForm.value;
          this.updateFilteredEmails();
        }
        this.closeDialog();
      }
    }
  }

  getDialogTitle(dialogType: string | null): string {

    switch (dialogType) {
      case 'relationshipType':
        return 'Relationship Type';
      case 'email':
        return 'Email';
      case 'phoneNumber':
        return 'Phone Number';
      case 'address':
        return 'Address';
      default:
        return '';
    }
  }

  // validateGIAndProceed( nextCallback: EventEmitter<void>) {
  //   if(this.form.get('prefix')?.value ==='')
  //     this.form.get('prefix')?.setErrors(null);
  //     if(this.form.get('firstName')?.value ==='')
  //     this.form.get('firstName')?.setErrors(null);
  //     if(this.form.get('middleName')?.value ==='')
  //     this.form.get('middleName')?.setErrors(null);
  //     if(this.form.get('lastName')?.value ==='')
  //     this.form.get('lastName')?.setErrors(null);
    
  //     if(this.giValidation()) {
  //        this.isGIhasErrors= true;
  //      }
  //   else {
  //     this.isGIhasErrors= false;
  //     nextCallback.emit();
  //   }
  // }
   validateGIAndProceed( nextCallback: EventEmitter<void>) {
     if(!this.formValidationError()) {
      
       const donorTypeIdValue = this.form.get('donorTypeId')?.value;
       if (donorTypeIdValue !== '7' && this.form.get('organization')?.value ==='') {
         this.form.get('organization')?.markAsTouched();
         this.form.get('organization')?.markAsDirty();
         this.form.get('organization')?.setErrors({ required: true });
       }
       if (donorTypeIdValue === '7') {
         if( this.form.get('firstName')?.value ==='') {
           this.form.get('firstName')?.markAsTouched();
           this.form.get('firstName')?.markAsDirty();
           this.form.get('firstName')?.setErrors({ required: true });
       }
         if( this.form.get('lastName')?.value ===''){
           this.form.get('lastName')?.markAsTouched();
           this.form.get('lastName')?.markAsDirty();
           this.form.get('lastName')?.setErrors({ required: true });
         }
      }
       this.isGIhasErrors= true;
     }
     else
     {
      if(this.form.get('prefix')?.value ==='')
      this.form.get('prefix')?.setErrors(null);
      if(this.form.get('firstName')?.value ==='')
      this.form.get('firstName')?.setErrors(null);
      if(this.form.get('middleName')?.value ==='')
      this.form.get('middleName')?.setErrors(null);
      if(this.form.get('lastName')?.value ==='')
      this.form.get('lastName')?.setErrors(null);
      
     if(this.form.get('prefix')?.invalid || this.form.get('firstName')?.invalid || this.form.get('middleName')?.invalid || this.form.get('lastName')?.invalid){
       this.isGIhasErrors= true;
      }
      else{
        this.isGIhasErrors= false;
        nextCallback.emit();
      }
     }
     return this.isGIhasErrors;
   }

 giValidation(){
  let isError: boolean= false;
  if(!this.formValidationError()) {
      
    const donorTypeIdValue = this.form.get('donorTypeId')?.value;
    if (donorTypeIdValue !== '7' && this.form.get('organization')?.value ==='') {
      this.form.get('organization')?.markAsTouched();
      this.form.get('organization')?.markAsDirty();
      this.form.get('organization')?.setErrors({ required: true });
    }
    if (donorTypeIdValue === '7') {
      if( this.form.get('firstName')?.value ==='') {
        this.form.get('firstName')?.markAsTouched();
        this.form.get('firstName')?.markAsDirty();
        this.form.get('firstName')?.setErrors({ required: true });
      }
      if( this.form.get('lastName')?.value ===''){
        this.form.get('lastName')?.markAsTouched();
        this.form.get('lastName')?.markAsDirty();
        this.form.get('lastName')?.setErrors({ required: true });
      }
    }
    isError= true;
  }
    if(this.form.get('prefix')?.invalid || ((this.form.get('donorTypeId')?.value ===7) && (this.form.get('firstName')?.invalid|| this.form.get('lastName')?.invalid)) || this.form.get('middleName')?.invalid ){
      isError= true;
    }
  return isError;
 }

  validateContactsAndProceed( nextCallback: EventEmitter<void>) {

    const addressGroup = this.form.get('address')?.value;
    const phoneGroup = this.form.get('phoneNumber')?.value;
    const emailGroup = this.form.get('email')?.value;
   
    const isAddressValid = Object.keys(addressGroup).some(k=> k !== 'addressTypeId' && addressGroup[k]);
    const isPhoneValid = Object.keys(phoneGroup).some(k=> k !== 'phoneType' && phoneGroup[k]);
    const isEmailValid = Object.keys(emailGroup).some(k=> k !== 'type' && emailGroup[k]);

    if(!isAddressValid && !isPhoneValid && !isEmailValid)
    {
      this.isContactshasErrors= false;
      nextCallback.emit();
    }
    else {
      this.isContactshasErrors= true;
    }
  }
  
  validateRelationshipAndProceed( nextCallback: EventEmitter<void>) {
    const relationshipTypesGroup = this.form.get('relationshipTypes')?.value;
    const isrelationshipTypesValid= Object.keys(relationshipTypesGroup).some(k => (k !== 'relatedDonorId' && k !== 'isHousehold') && relationshipTypesGroup[k]);
    
    if(!isrelationshipTypesValid )
    {
      this.isRelationshipHasErrors= false;
      nextCallback.emit();
    }
    else {
      this.isRelationshipHasErrors= true;
    }
  }

  formValidationError() {
    const donorTypeIdValue = this.form.get('donorTypeId')?.value;

    if (donorTypeIdValue !== '7' && this.form.get('organization')?.value ==='') {
      this.form.get('organization')?.setValidators([Validators.required]);  
      this.form.get('organization')?.setErrors({ invalid: true });
      return this.form.get('organization')?.valid ?? false;  
    }
    else if(donorTypeIdValue === '7'){
      this.form.get('organization')?.clearValidators();
      const isDonorTypeIdValid = this.form.get('donorTypeId')?.valid ?? false;

      if(this.form.get('firstName')?.value ==='') {
         this.form.get('firstName')?.setErrors({ required: true });
      }

      if(this.form.get('lastName')?.value ==='') {
        this.form.get('firstName')?.setErrors({ required: true });
      }
      const isFirstNameValid = this.form.get('firstName')?.valid ?? false;
      const isLastNameValid = this.form.get('lastName')?.valid ?? false;

      return isDonorTypeIdValid && isFirstNameValid && isLastNameValid;
    }
    return true;
  }

  giReset() {
    this.form.patchValue({
      prefix: '',
      firstName: '',
      middleName: '',
      lastName: '',
      suffix: '',
      employeeType: '',
      employmentStatus: '',
      employer: '',
      occupation: '',
      fecCommitteeId: '',
      organization: '',
      donorId: '',
      legacyId: '',
      candidateId: '',
      dateOfBirth: '',
    });
    this.form.get('currentlyEmployed')?.setValue('yes');
  }

  reset() {
    this.form.reset({
      donorTypeId: '',
      prefix: '',
      firstName: '',
      middleName: '',
      lastName: '',
      suffix: '',
      employeeType: '',
      employmentStatus: '',
      employer: '',
      occupation: '',
      fecCommitteeId: '',
      organization: '',
      donorId: '',
      legacyId: '',
      candidateId: '',
      dateOfBirth: '',
      addresses: [],
      phoneNumbers: [],
      emails: [],
      relationshipTypeOptions: [],
      informalName: '',
      formalSalutation: '',
      mailSalutation: '',
      donorFlagIds: [],
      doNotCall: false,
      doNotEmail: false,
      doNotMail: false,
      doNotSolicit: false,
      isDeceased: false,
    });

    this.form.get('currentlyEmployed')?.setValue('yes');
    this.savedAddresses = [];
    this.savedPhoneNumbers = [];
    this.savedEmails = [];
    this.savedDonorFlags = [];
    this.savedRelationships = [];
  }

  donorTypeChanged(event: any) {
    var id = Number(event.value.value);
    this.loadSelectedDonor(id);
  }

  loadSelectedDonor(donorTypeId: Number) {
    if (this.donorTypes)
      {
        this.selectedDonorType = this.donorTypes.find(dt => Number(dt.value) === donorTypeId) || null;
        this.form.get('donorTypeId')?.setValue(this.selectedDonorType?.value);
      }
  }

  save(event: any) {
   
    if(!this.form.get('donorTypeId')?.value || this.giValidation()) {
      if(!this.form.get('donorTypeId')?.value){
        this.form.get('donorTypeId')?.markAsTouched();
        this.form.get('donorTypeId')?.setErrors({ required : true });
      }
      return;
    }

    const flags = this.savedDonorFlags.map((flag) => parseInt(flag.key, 10));

    const donorFormModel: DonorFormModel = {
      campaignId:this.campaignId,  //0,
      donorTypeId: this.form.value.donorTypeId || '',
      prefix: this.form.value.prefix || '',
      firstName: this.form.value.firstName || '',
      middleName: this.form.value.middleName || '',
      lastName: this.form.value.lastName || '',
      suffix: this.form.value.suffix || '',
      currentlyEmployed: this.form.value.currentlyEmployed === 'yes' ? true : false,
      employeeType: this.form.value.employeeType || '',
      employmentStatus: this.form.value.employmentStatus || '',
      employer: this.form.value.employer || '',
      occupation: this.form.value.occupation || '',
      fecCommitteeId: this.form.value.fECCommitteeID || '',
      organization: this.form.value.organization || '',
      donorId: this.form.value.donorId || '',
      legacyId: this.form.value.legacyId || '',
      candidateId: this.form.value.candidateId || '',
      dateOfBirth: this.form.value.dateOfBirth || '',

      addresses: this.savedAddresses,
      phoneNumbers: this.savedPhoneNumbers,
      emails: this.savedEmails,
      relationshipTypes: this.savedRelationships || '',
      informalName: this.form.value.informalName || '',
      formalSalutation: this.form.value.formalSalutation || '',
      mailSalutation: this.form.value.mailSalutation || '',

      donorFlagIds: flags,
      doNotCall: this.form.value.doNotCall || false,
      doNotEmail: this.form.value.doNotEmail || false,
      doNotMail: this.form.value.doNotMail || false,
      doNotSolicit: this.form.value.doNotSolicit || false,
      isDeceased: this.form.value.isDeceased || false,
    };

    console.debug(donorFormModel);

    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: (this.isEditMode)? 'Are you sure you want to save this existing donor?' : 'Are you sure you want to save this new donor?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: "none",
      rejectIcon: "none",
      rejectButtonStyleClass: "p-button-text",
      accept: () => {
        this.donorAddService.saveDonor(donorFormModel).subscribe({
          next: (res) => {
            this.messageService.add({ severity: 'success', summary: 'Success', detail: 'The donor has been saved successfully.' });
            this.reset();
            donorFormModel.donorId = res?.donorId;
            donorFormModel?.addresses?.forEach((a: any) => {
              a.state = this.states.find((o: any) => o.key === a.stateId)?.value ?? null;
            });
            this.searchService.emitSaveDonor(donorFormModel);
            this.addDonorStepper.activeStep = 0;
            if (this.isEditMode) {
              this.closeDialog();
              this.isEditMode = false;
              window.location.reload();
            }
          },
          error: (error) => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to save donor information.\n Please review the details and try again!' });
          },
        });
      },
      reject: () => {
      }
    });
  }
}