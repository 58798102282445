import { Component, effect, Input, SimpleChanges } from "@angular/core";
import { MainModel } from "../../../global/model/MainModel";
import { DonorCardService } from "../../services/donor-card.service";
import { TableModule } from "primeng/table";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { ButtonModule } from "primeng/button";
import { SearchService } from "../../../global/services/data/SearchService/searchservice";

@Component({
    selector: "app-donor-fund-totals-grid",
    standalone: true,
    imports: [CommonModule, TableModule, FormsModule, ButtonModule],
    providers: [DonorCardService],
    templateUrl: './donor-fund-totals-grid.component.html',
    styleUrls: ['./donor-fund-totals-grid.component.scss']
})
export class DonorFundTotalsGridComponent {
    @Input() donorId: string = '';
    @Input() displayOneModalPopup: boolean = false;
    fundcodesHistory = [];

    constructor(private mainModel: MainModel,
      private donorCardService: DonorCardService,
      private searchService: SearchService) {
        effect(() => {
            this.loadFundcodesHistory();
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
      if (changes['donorId']) {
        this.loadFundcodesHistory();
      }
    }

    loadFundcodesHistory(): void {      
      const c: any = this.mainModel.selectedCampaign();

        if (!this.donorId || !c?.campaignId)
            return;

        this.donorCardService.getFundcodesHistory(parseInt(c?.campaignId), parseInt(this.donorId)).subscribe({
          next: data => {
            this.fundcodesHistory = data;
          },
          error: err => {
            console.error('Error while fetching fundcodes history data from api: ', err);
          }
        })
    }

  onClose() {
    this.searchService.emitCloseDonorGivingHistory();
    }

}
