import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { APP_CONFIG } from '../../../config/config.token';
import { AppConfig } from '../../../config/app.config';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  private donorSelected = new BehaviorSubject<any>(null);
  private compareDonors = new BehaviorSubject<any>(null);
  private addNewDonor = new BehaviorSubject<any>(null);
  private closeNewDonor = new BehaviorSubject<any>(null);
  private closeCompareDonors = new BehaviorSubject<any>(null);
  private saveDonor = new BehaviorSubject<any>(null);
  private showDonorGivingHistory = new BehaviorSubject<any>(null);
  private closeShowDonorGivingHistory = new BehaviorSubject<any>(null);
  donorSelected$ = this.donorSelected.asObservable();
  addNewDonor$ = this.addNewDonor.asObservable();
  closeNewDonor$ = this.closeNewDonor.asObservable();
  saveDonor$ = this.saveDonor.asObservable();
  compareDonors$ = this.compareDonors.asObservable();
  closeCompareDonors$ = this.closeCompareDonors.asObservable();
  showDonorsGivingHistory$ = this.showDonorGivingHistory.asObservable();
  closeShowDonorGivingCompareDonors$ = this.closeShowDonorGivingHistory.asObservable();

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig) {
  }

  emitDonorSelected(selectedDonor: any) {
    this.donorSelected.next(selectedDonor);
  }

  emitCompareDonorsSelected(selectedDonors: any) {
    this.compareDonors.next(selectedDonors);
  }

  emitShowDonorGivingHistory(donorId: any) {
    this.showDonorGivingHistory.next(donorId);
  }

  emitSaveDonor(donor: any) {
    this.saveDonor.next(donor);
  }

  emitAddNewDonorSelected() {
    this.addNewDonor.next('test');
  }

  emitCloseDonorModal() {
    this.closeNewDonor.next('test');
  }

  emitCloseCompareDonorModal() {
    this.closeCompareDonors.next('test');
  }

  emitCloseDonorGivingHistory() {
    this.closeShowDonorGivingHistory.next('test');
  }



  client!: HttpClient;
  getRawData(dataType: string, params: { campaignId: number; searchFilters: string; }) {
    var url = "";
    switch (dataType) {
      case 'donors':
        url = `${this.config.apiUrl}donor/donor-search`
        break;
      case 'donations':
        url = `${this.config.apiUrl}donation/donation-search`
        break
      case 'expenses':
        url = `${this.config.apiUrl}expense/expense-search`
        break
      default:
        break;
    }
    return this.http.get<any>(url, { params, withCredentials: true });
  }

  getapprovals(params: { requestId: number; }) {
    var url = `${this.config.apiUrl}expense/expense-approvals`
    return this.http.get<any>(url, { params, withCredentials: true });
  }
}
