<h1 class="capitalize">{{dataType ? dataType.split('/')[0].slice(0, -1) : ""}} Search</h1>


<!-- 
<p>
    You will need to configure: 
</p>
<ul>
    <li>Which type of data will be searched & listed here</li>
    <li>What url each item will link to - e.g. /donor/id or /donations/id</li>
</ul> -->
<app-search 
    [pagingModel]="pagingModel"
    [showInDialog]="showInDialog"
    [columnData]="columnDataResult"
    (dataViewUpdate)="queryData($event)" 
    [selectedSearchArea]="selectedSearchArea" 
    [searchKeyword]="searchKeyword"
    ngIf="pagingModel.data.length" 
    (cancelEvent)="handleCancel()"
    />
