<p-confirmDialog />
<p-toast />
<div id="addnewdonor">
  <h1 *ngIf="!isModalPopup">Add New Donor</h1>
  <p-stepper (activeStepChange)="stepChange($event)" #addDonorStepper orientation="vertical"  >
    <p-stepperPanel header="General Information">
      <ng-template pTemplate="content" let-nextCallback="nextCallback" let-index="index">
        <form [formGroup]="form">
          <p-card>
            <div class="formgrid grid">
              <div class="field col-12 sm:col-6 xl:col-4">
                <label for="donorTypeId" class="field-label required">Donor Type</label>
                <p-dropdown id="donorTypeId" formControlName="selectedDonorType" [options]="donorTypes" optionLabel="text"
                            placeholder="Please Select" (onChange)="donorTypeChanged($event)" dataKey="value" />
                <div *ngIf="form.controls['donorTypeId'].invalid && form.controls['donorTypeId'].touched" class="block p-error">
                  <small id="donorTypeid-error" class="error"> Please select Donor Type. </small>
                </div>
              </div>
            </div>
          </p-card>

          <p-card *ngIf="form.controls['donorTypeId']?.value">
            <div class="formgrid grid">

              <div class="field col-12 sm:col-6 md:col-4 xl:col-1 xxl:col-1">
                <label for="prefix" class="field-label">Prefix</label>
                <input id="prefix" type="text" pInputText formControlName="prefix" />
                <div *ngIf="form.controls['prefix'].errors && form.controls['prefix'].touched" class="block p-error">
                  <small class="error" *ngIf="form.controls['prefix'].errors?.['invalidName']">
                    Please enter a valid prefix.
                  </small>
                  <small class="error" *ngIf="form.controls['prefix'].errors?.['noNumbers']">
                    Numbers are not allowed.
                  </small>
                </div>
              </div>

              <div class="field col-12 sm:col-6 md:col-4 xl:col-3 xxl:col-3">
                <label for="firstName" [class]="labelClassR">First Name</label>
                <input id="firstName" type="text" pInputText formControlName="firstName" />
                <div *ngIf="form.controls['firstName'].errors && form.controls['firstName'].touched"
                     class="block p-error">
                  <small class="error" *ngIf="form.controls['firstName'].errors?.['required']">
                    Please enter your First Name.
                  </small>
                  <small class="error" *ngIf="form.controls['firstName'].errors?.['invalidName']">
                    The First Name contains invalid characters.
                  </small>
                  <small class="error" *ngIf="form.controls['firstName'].errors?.['noNumbers']">
                    Numbers are not allowed in the First Name.
                  </small>
                </div>
              </div>

              <div class="field col-12 sm:col-6 md:col-4 xl:col-3">
                <label for="middleName" class="field-label">Middle Name</label>
                <input id="middleName" type="text" pInputText formControlName="middleName" />
                <div *ngIf="form.controls['middleName'].invalid && form.controls['middleName'].touched"
                     class="block p-error">
                  <small class="error" *ngIf="form.controls['middleName'].errors?.['invalidName']">
                    The Middle Name contains invalid characters.
                  </small>
                  <small class="error" *ngIf="form.controls['middleName'].errors?.['noNumbers']">
                    Numbers are not allowed in the Middle Name.
                  </small>
                </div>
              </div>

              <div class="field col-12 sm:col-6 md:col-4 xl:col-3 xxl:col-3">
                <label for="lastName" [class]="labelClassR">Last Name</label>
                <input id="lastName" type="text" pInputText formControlName="lastName" />
                <div *ngIf="form.controls['lastName'].invalid && form.controls['lastName'].touched"
                     class="block p-error">
                  <small class="error" *ngIf="form.controls['lastName'].errors?.['required']">
                    Please enter your Last Name.
                  </small>
                  <small class="error" *ngIf="form.controls['lastName'].errors?.['invalidName']">
                    The Last Name contains invalid characters.
                  </small>
                  <small class="error" *ngIf="form.controls['lastName'].errors?.['noNumbers']">
                    Numbers are not allowed in the Last Name.
                  </small>
                </div>
              </div>

              <div class="field col-12 sm:col-6 md:col-4 xl:col-2 xxl:col-1">
                <label for="suffix" class="field-label">Suffix</label>
                <input id="suffix" type="text" pInputText formControlName="suffix" />
              </div>

            </div>
          </p-card>

          <p-card *ngIf="form.controls['donorTypeId']?.value && form.controls['donorTypeId'].value === '7' ">
            <div class="formgrid grid">

              <div class="field col-12 sm:col-6 xl:col-4">
                <label for="currentlyEmployed" class="field-label">Currently Employed?</label>
              </div>
              <div class="field col-12 sm:col-6 xl:col-4">
                <div class="flex flex-wrap gap-3">
                  <div class="flex align-items-center">
                    <p-radioButton name="currentlyEmployed" value="yes"
                                   formControlName="currentlyEmployed"></p-radioButton>
                    <label for="currentlyEmployed" class="ml-2">Yes</label>
                  </div>
                  <div class="flex align-items-center">
                    <p-radioButton name="currentlyEmployed" value="no"
                                   formControlName="currentlyEmployed"></p-radioButton>
                    <label for="currentlyEmployed" class="ml-2">No</label>
                  </div>
                </div>
              </div>
            </div>

            <div class="formgrid grid">

              <div class="field col-12 sm:col-6 md:col-4 xl:col-3" *ngIf="isEmployed === 'no'">
                <label for="employmentStatus" class="field-label">Employment Status</label>
                <p-dropdown id="employmentStatus" formControlName="employmentStatus" [options]="employmentStatuses"
                            optionLabel="name" optionValue="value" placeholder="Please Select" />
              </div>

              <div class="field col-12 sm:col-6 md:col-4 xl:col-3" *ngIf="isEmployed === 'yes'">
                <label for="employer" class="field-label">Employer</label>
                <input id="employer" type="text" pInputText formControlName="employer" />
              </div>

              <div class="field col-12 sm:col-6 md:col-4 xl:col-3" *ngIf="isEmployed === 'yes'">
                <label for="occupation" class="field-label">Occupation</label>
                <input id="occupation" type="text" pInputText formControlName="occupation" />
              </div>

            </div>
          </p-card>

          <p-card *ngIf="form.controls['donorTypeId']?.value">
            <div class="formgrid grid">

              <div class="field  col-12 sm:col-6 md:col-4 xl:col-3" *ngIf="form.controls['donorTypeId'].value !== '7' ">
                <label for="organization" [class]="labelClassNR">Organization</label>
                <input id="organization" type="text" pInputText formControlName="organization" />
                <div *ngIf="form.controls['organization'].invalid && form.controls['organization'].touched"
                class="block p-error">
                  <small class="error" *ngIf="form.controls['organization'].errors?.['required']">
                    Please enter your organization.
                  </small>
                </div>
              </div>

              <div class="field col-12 sm:col-6 md:col-4 xl:col-3">
                <label for="donorId" class="field-label">Donor ID</label>
                <input id="donorId" type="text" pInputText formControlName="donorId" [readonly]="true" [disabled]="true" />
              </div>

              <div class="field  col-12 sm:col-6 md:col-4 xl:col-3">
                <label for="legacyId" class="field-label">Legacy ID</label>
                <input inputId="legacyId" id="legacyId" type="text" pInputText formControlName="legacyId"
                       (keypress)="allowOnlyNumbers($event)" />
              </div>

              <div class="field  col-12 sm:col-6 md:col-4 xl:col-3" *ngIf="form.controls['donorTypeId'].value !== '7' ">
                <label for="fECCommitteeID" class="field-label">FEC Committee ID</label>
                <input id="fECCommitteeID" type="text" pInputText formControlName="fECCommitteeID" />
              </div>

              <div class="field  col-12 sm:col-6 md:col-4 xl:col-3" *ngIf="form.controls['donorTypeId'].value === '7' ">
                <label for="candidateId" class="field-label">Candidate Id</label>
                <input id="candidateId" type="text" pInputText formControlName="candidateId" />
              </div>

              <div class="field  col-12 sm:col-6 md:col-4 xl:col-3" *ngIf="form.controls['donorTypeId'].value === '7' ">
                <label for="dateOfBirth" class="field-label">Date of Birth</label>
                <p-calendar id="dateOfBirth" formControlName="dateOfBirth" [showIcon]="true" [iconDisplay]="'input'" placeholder="MM/DD/YYYY">
                </p-calendar>
              </div>

            </div>
          </p-card>
        </form>

        <p-card *ngIf="form.controls['donorTypeId']?.value">
          <div *ngIf="isGIhasErrors" class="block p-error">
            <small class="error" >
              There are some errors in this section. Please correct them before proceeding.
            </small>
          </div>
          <rc-generic-button level="secondary" borderStyle="solid" label="Next" (click)="validateGIAndProceed(nextCallback)" />
        </p-card>
       
      </ng-template>
    </p-stepperPanel>

    <p-stepperPanel header="Contact">
      <ng-template pTemplate="content" let-prevCallback="prevCallback" let-nextCallback="nextCallback" let-index="index">
        <form [formGroup]="form">
          <p-card>
            <div formGroupName="address">
              <div class="grid gap-5">
                <div class="col">
                  <div class="formgrid grid">

                    <div class="field col-12 sm:col-6 md:col-4 lg:col-3 xl:col-4 xxl:col-3">
                      <label for="addressTypeId" class="field-label">Address Type</label>
                      <p-dropdown id="addressTypeId" formControlName="addressTypeId" [options]="filteredAddressTypes"
                        optionLabel="value" placeholder="Please Select" optionValue="key" />
                    </div>

                    <div class="field col-12 sm:col-6 md:col-8 lg:col-9 xl:col-8 xxl:col-9">
                      <label class="field-label">Primary Address</label>
                      <p-toggleButton name="isPrimary" onLabel="Yes" offLabel="No" onIcon="pi pi-check-circle"
                                      offIcon="pi pi-ban" formControlName="isPrimary" />
                    </div>

                    <div class="field col-12 sm:col-6 md:col-4 lg:col-3 xl:col-4 xxl:col-3">
                      <label for="addressLine1" class="field-label">Address 1</label>
                      <input id="addressLine1" type="text" pInputText formControlName="addressLine1" />
                      <div *ngIf="isAddressFieldsMandate" class="block p-error">
                        <small class="error">
                          Please enter at least one address field.
                        </small>
                      </div>
                    </div>

                    <div class="field col-12 sm:col-6 md:col-4 lg:col-3 xl:col-4 xxl:col-3">
                      <label for="addressLine2" class="field-label">Address 2</label>
                      <input id="addressLine2" type="text" pInputText formControlName="addressLine2" />
                    </div>

                    <div class="field col-12 sm:col-6 md:col-4 lg:col-3 xl:col-4 xxl:col-3">
                      <label for="city" class="field-label">City</label>
                      <input id="city" type="text" pInputText formControlName="city" />
                    </div>

                    <div class="field col-12 sm:col-6 md:col-4 lg:col-3 xl:col-4 xxl:col-3">
                      <label for="stateId" class="field-label">State</label>
                      <p-dropdown id="stateId" formControlName="stateId" [options]="states" optionLabel="value"
                                  placeholder="Please Select" optionValue="key" />
                    </div>

                    <div class="field col-12 sm:col-6 md:col-4 lg:col-3 xl:col-4 xxl:col-3">
                      <label for="zipcode" class="field-label">Zip Code</label>
                      <input id="zipcode" type="text" pInputText formControlName="zipcode" />
                    </div>

                    <div class="field col-12 sm:col-6 md:col-4 lg:col-3 xl:col-4 xxl:col-3">
                      <label for="county" class="field-label">County</label>
                      <input id="county" type="text" pInputText formControlName="county" />
                    </div>

                  </div>

                  <div class="flex justify-content-end align-items-center mt-2">
                    <rc-generic-button label="Clear Fields" (click)="clearAddress()" iconImg="xmark"
                                       borderStyle="text-only" level="secondary" size="small" [classes]="['mr-1']" />
                    <rc-generic-button label="Save Address" (click)="addAddress()" 
                    level="secondary" borderStyle="outline" />
                  </div>

                </div>

                <div class="col-12 xl:col-5 xxl:col-4 section-divider" *ngIf="savedAddresses.length>0">

                  <p-table [value]="savedAddresses" styleClass="datatable-sm add-donor-datatable">
                    <ng-template pTemplate="header">
                      <tr>
                        <th>Addresses</th>
                        <th></th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-address>
                      <tr id="address-row">
                        <td>
                          <span [innerHTML]="getFormattedAddress(address)"></span>
                        </td>
                        <td>
                          <rc-generic-button label="Edit" iconImg="cog" iconColor="teal-light" [isIconOnly]="true" [classes]="['table-action-btn']" (click)="editAddress(address)" />
                          <rc-generic-button label="Delete" iconImg="trash" iconColor="red-light" [isIconOnly]="true"
                            [classes]="['table-action-btn']" (click)="confirmDelete($event, address, savedAddresses, 'Are you sure you want to delete this address?', 'address')" />
                        </td>
                      <tr>
                    </ng-template>
                  </p-table>

                </div>
              </div>
            </div>
          </p-card>

          <p-card>
            <div formGroupName="phoneNumber">
              <div class="grid gap-5">
                <div class="col">
                  <div class="formgrid grid">

                    <div class="field col-12 sm:col-6 xl:col-5 xxl:col-4">
                      <label for="phoneType" class="field-label">Phone Type</label>
                      <p-dropdown id="phoneType" formControlName="phoneType" [options]="filteredPhoneTypes"
                        optionLabel="value" placeholder="Please Select" optionValue="key" />

                        <!-- <div *ngIf="form.controls['phoneType'].invalid && form.controls['phoneType'].touched"
                           class="block p-error">
                          <small id="phoneType-error" class="error">
                            Please select PhoneType.
                          </small>
                        </div> -->
                    </div>

                    <div class="field col-12 sm:col-6 xl:col-5 xxl:col-4">

                      <!-- <rc-input
                      label="Phone Number"
                      mask="999-999-9999"
                      placeholder="999-999-9999"
                      type="masked"
                      [control]="form.controls['phoneNumber']"
                      /> -->

                      <label for="phoneNumber" class="field-label">Phone Number</label>
                      <input id="phoneNumber" type="text" pInputText formControlName="phoneNumber" maxlength="14"
                             (input)="formatPhoneNumber($event,'phoneNumber')" (keypress)="allowOnlyNumbers($event)" />
                      <div *ngIf="form.controls['phoneNumber'].invalid && form.controls['phoneNumber'].touched"
                           class="block p-error">
                        <small id="phoneNumber-error" class="error">
                          Enter valid Phone Number
                        </small>
                      </div>

                    </div>
                  </div>

                  <div class="flex justify-content-end align-items-center mt-2">
                    <rc-generic-button label="Clear Fields" (click)="clearPhoneNumber()" iconImg="xmark"
                                       borderStyle="text-only" level="secondary" size="small" />
                    <rc-generic-button level="secondary" label="Save Phone" borderStyle="outline"
                    (click)="addPhoneNumber()" />
                  </div>
                </div>

                <div class="col-12 xl:col-5 xxl:col-4 section-divider" *ngIf="savedPhoneNumbers.length>0">

                  <p-table [value]="savedPhoneNumbers" styleClass="datatable-sm add-donor-datatable">
                    <ng-template pTemplate="header">
                      <tr>
                        <th>Phone Numbers</th>
                        <th></th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-phoneNumber>
                      <tr id="phone-row">
                        <td>
                          <span [innerHTML]="getFormattedPhoneNumber(phoneNumber)"></span>
                        </td>
                        <td>
                          <rc-generic-button label="Edit" iconImg="cog" iconColor="teal-light" [isIconOnly]="true" [classes]="['table-action-btn']" (click)="editPhoneNumber(phoneNumber)" />
                          <rc-generic-button label="Delete" iconImg="trash" iconColor="red-light" [isIconOnly]="true"
                            [classes]="['table-action-btn']" (click)="confirmDelete($event, phoneNumber, savedPhoneNumbers, 'Are you sure you want to delete this phone number?','phone')" />
                        </td>
                      <tr>
                    </ng-template>
                  </p-table>

                </div>
              </div>
            </div>
          </p-card>

          <p-card>
            <div formGroupName="email">
              <div class="grid gap-5">
                <div class="col">
                  <div class="formgrid grid">
                    <div class="field col-12 sm:col-6 xl:col-5 xxl:col-4">
                      <label for="type" class="field-label">Email Type</label>
                      <p-dropdown id="type" formControlName="type" [options]="filteredEmailTypes"
                        optionLabel="value" placeholder="Please Select" optionValue="key" />
                    </div>
                    <div class="field col-12 sm:col-6 xl:col-5 xxl:col-4">
                      <label for="email" class="field-label">Email</label>
                      <input id="email" type="email" pInputText formControlName="email" placeholder="" />
                      <div *ngIf="form.controls['email'].invalid && form.controls['email'].touched" class="block p-error">
                        <small id="email-error" class="error">Please enter a valid Email Address.</small>
                      </div>
                    </div>
                  </div>

                  <div class="flex justify-content-end align-items-center mt-2">
                    <rc-generic-button label="Clear Fields" (click)="clearEmail()" iconImg="xmark"
                                       borderStyle="text-only" level="secondary" size="small" [classes]="['mr-1']" />
                    <rc-generic-button level="secondary" label="Save Email" borderStyle="outline" (click)="addEmail()" />
                  </div>

                </div>

                <div class="col-12 xl:col-5 xxl:col-4 section-divider" *ngIf="savedEmails.length>0">

                  <p-table [value]="savedEmails" styleClass="datatable-sm add-donor-datatable">
                    <ng-template pTemplate="header">
                      <tr>
                        <th>Emails</th>
                        <th></th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-email>
                      <tr id="email-row">
                        <td>
                          <span [innerHTML]="getFormattedEmail(email)"></span>
                        </td>
                        <td>
                          <rc-generic-button label="Edit" iconImg="cog" iconColor="teal-light" [isIconOnly]="true" [classes]="['table-action-btn']" (click)="editEmail(email)" />
                          <rc-generic-button label="Delete" iconImg="trash" iconColor="red-light" [isIconOnly]="true"
                            [classes]="['table-action-btn']" (click)="confirmDelete($event, email, savedEmails, 'Are you sure you want to delete this email?','email')" />
                        </td>
                      <tr>
                    </ng-template>
                  </p-table>

                </div>
              </div>
            </div>
          </p-card>

          <p-card>
            <div *ngIf="isContactshasErrors" class="block p-error">
              <small class="error" > There are unsaved changes in form, Please complete before proceeding. </small>
            </div>
            <rc-generic-button level="secondary" borderStyle="outline" label="Back" (click)="prevCallback.emit()"
                               [classes]="['mr-1']" />
           <rc-generic-button level="secondary" borderStyle="solid" label="Next" (click)="validateContactsAndProceed(nextCallback)" />
          </p-card>

        </form>
      </ng-template>

    </p-stepperPanel>

    <p-stepperPanel header="Relationships">
      <ng-template pTemplate="content" let-prevCallback="prevCallback" let-nextCallback="nextCallback" let-index="index">
        <form [formGroup]="form">

          <p-card>
            <div formGroupName="relationshipTypes">
              <div class="grid gap-5">

                <div class="col">
                  <div class="formgrid grid">
                    <div class="field col-12 sm:col-6 xl:col-5 xxl:col-4">
                      <label for="firstName" class="field-label">First Name</label>
                      <p-autoComplete id="firstName" formControlName="firstName" [suggestions]="filteredFirstNames"
                                      [field]="'text'" placeholder="Please Select" (onSelect)="onFirstNameSelect($event)" [emptyMessage]="'No donors found'">
                        <ng-template let-suggestion pTemplate="empty">
                          <div class="no-result">
                            <span>No donors found</span>
                          </div>
                          <div>&nbsp;</div>
                          <div class="add-donor">
                            <rc-generic-button label="Add New Donor" iconImg="plus" borderStyle="text-only" level="secondary" size="small" [classes]="['mx-1']" (click)="showAddNewDonorDialog = true" />
                          </div>
                        </ng-template>
                      </p-autoComplete>
                        <div *ngIf=" form.get('relationshipTypes.firstName')?.invalid && form.get('relationshipTypes.firstName')?.touched" class="block p-error">
                          <small class="error"> Please select first name. </small>
                        </div>
                        <div *ngIf="inValidFirstName">
                          <small id="relationshipType-error" class="error">Please select a valid first name.</small>
                        </div>
                    </div>

                    <div class="field col-12 sm:col-6 xl:col-5 xxl:col-4">
                      <label for="lastName" class="field-label">Last Name</label>
                      <p-autoComplete id="lastName" formControlName="lastName" [suggestions]="filteredLastNames"
                                      [field]="'text'" placeholder="Please Select" (onSelect)="onLastNameSelect($event)" [emptyMessage]="'No donors found'">
                        <ng-template let-suggestion pTemplate="empty">
                          <div class="no-result">
                            <rc-generic-button label="Add New Donor" iconImg="plus" borderStyle="text-only" level="secondary" size="small" [classes]="['mx-1']" (click)="showAddNewDonorDialog = true" />
                          </div>
                        </ng-template>
                      </p-autoComplete>
                      <div *ngIf=" form.get('relationshipTypes.lastName')?.invalid && form.get('relationshipTypes.lastName')?.touched" class="block p-error">
                        <small class="error"> Please select last Name. </small>
                      </div>
                      <div *ngIf="inValidLastName">
                        <small id="relationshipType-error" class="error">
                          Please select a valid last name.
                        </small>
                      </div>
                    </div>

                    <div class="field col-12 sm:col-6 xl:col-5 xxl:col-4">
                      <label for="relationshipTypeId" class="field-label">Relationship Type</label>
                      <p-dropdown id="relationshipTypeId" formControlName="relationshipTypeId"
                        [options]="filteredRelationshipTypeOptions" optionLabel="text" placeholder="Please Select"
                        optionValue="value" />
                        <div *ngIf=" form.get('relationshipTypes.relationshipTypeId')?.invalid && form.get('relationshipTypes.relationshipTypeId')?.touched" class="block p-error">
                          <small class="error"> Please select relationship type. </small>
                        </div>

                      <rc-generic-button label="Add New Type" iconImg="plus" borderStyle="text-only" level="secondary" size="small" [classes]="['mx-1 mt-2']" (click)="showDialog = true" />
                    </div>

                    <div class="field col-12 sm:col-6 xl:col-5 xxl:col-4">
                      <label for=" " class="field-label">Same Household</label>
                      <p-toggleButton name="isHousehold" onLabel="Yes" offLabel="No" onIcon="pi pi-check-circle"
                                      offIcon="pi pi-ban" formControlName="isHousehold" />
                    </div>
                  </div>

                  <div class="flex justify-content-end align-items-center mt-2">
                    <rc-generic-button label="Clear Fields" (click)="clearRelationship()" iconImg="xmark" borderStyle="text-only" level="secondary" size="small" [classes]="['mr-1']" />
                    <rc-generic-button level="secondary" label="Save Relationship" borderStyle="outline" (click)="addRelationship()"/>
                  </div>

                </div>

                <div class="col-12 xl:col-5 xxl:col-4 section-divider" *ngIf="savedRelationships.length>0">

                  <p-table [value]="savedRelationships" styleClass="datatable-sm add-donor-datatable">
                    <ng-template pTemplate="header">
                      <tr>
                        <th>Relationships</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rs>
                      <tr>
                        <td>
                          <span [innerHTML]="getFormattedRT(rs)"></span>
                        </td>
                        <td>
                          <span [hidden]="!rs.isHousehold">
                            <i class="fa-sharp fa-regular fa-house-circle-check same-household-yes" title="Same Household"></i>
                          </span>
                          <span [hidden]="rs.isHousehold">
                            <i class="fa-sharp fa-light fa-house same-household-no" title="Not Same Household"></i>
                          </span>
                        </td>
                        <td>
                          <!-- <rc-generic-button label="Edit" iconImg="cog" iconColor="teal-light" [isIconOnly]="true"
                            [classes]="['table-action-btn']" (click)="editRT(rs)" /> -->
                          <rc-generic-button label="Delete" iconImg="trash" iconColor="red-light" [isIconOnly]="true"
                            [classes]="['table-action-btn']" (click)="confirmDelete($event, rs, savedRelationships, 'Are you sure you want to delete this relationship?','relationship')" />
                        </td>
                      <tr>
                    </ng-template>
                  </p-table>

                </div>
              </div>
            </div>

            <p-dialog [(visible)]="showDialog" [modal]="true" draggable="false"
                      styleClass="p-dialog-static p-dialog-sm fadeinright animation-duration-300"
                      (onHide)="clearSaveRelationshipType()">

              <ng-template pTemplate="header">
                <span class="h3 mb-0 white-space-nowrap">
                  Add New Relationship Type
                </span>
              </ng-template>

              <form [formGroup]="editSaveRelationshipType">

                <div class="formgrid grid">
                  <div class="field col-12">
                    <label for="relationshipType" class="field-label">Relationship Type</label>
                    <input id="relationshipType" type="text" pInputText formControlName="relationshipType" (input)="duplicateRelationshipType = false" />
                    <div *ngIf="editSaveRelationshipType.get('relationshipType')?.invalid && editSaveRelationshipType.get('relationshipType')?.touched">
                      <small id="relationshipType-error" class="error">
                        Please enter a valid relationship type.
                      </small>
                    </div>

                    <div *ngIf="duplicateRelationshipType">
                      <small id="relationshipType-error" class="error">
                        This relationship type already exists. Please choose a different one.
                      </small>
                    </div>

                  </div>
                </div>
              </form>

              <ng-template pTemplate="footer">
                <rc-generic-button label="Cancel" borderStyle="text-only" (click)="clearSaveRelationshipType()" />
                <rc-generic-button   label="Save" (click)="saveNewRelationship()" />
              </ng-template>
            </p-dialog>

            <p-dialog [(visible)]="showAddNewDonorDialog" [modal]="true" draggable="false"
                      styleClass="p-dialog-static p-dialog-sm fadeinright animation-duration-300"
                      (onHide)="clearAddNewDonorDialog()">

              <ng-template pTemplate="header">
                <span class="h3 mb-0 white-space-nowrap">
                  Add New Donor
                </span>
              </ng-template>

              <form [formGroup]="addNewDonorFromRelationship">
                <div class="formgrid grid">
                  <div class="field col-12">
                    <label for="firstName" [class]="labelClassR">First Name</label>
                    <input id="firstName" type="text" pInputText formControlName="firstName" />
                    <div *ngIf="addNewDonorFromRelationship.controls['firstName'].errors && addNewDonorFromRelationship.controls['firstName'].touched">
                      <small class="error" *ngIf="addNewDonorFromRelationship.controls['firstName'].errors?.['required']">
                        First name is required.
                      </small>
                      <small class="error" *ngIf="addNewDonorFromRelationship.controls['firstName'].errors?.['invalidName']">
                        Please enter a valid first name.
                      </small>
                      <small class="error" *ngIf="addNewDonorFromRelationship.controls['firstName'].errors?.['noNumbers']">
                        Numbers aren't allowed.
                      </small>
                    </div>
                  </div>

                  <div class="field col-12">
                    <label for="lastName" [class]="labelClassR">Last Name</label>
                    <input id="lastName" type="text" pInputText formControlName="lastName" />
                    <div *ngIf="addNewDonorFromRelationship.controls['lastName'].invalid && addNewDonorFromRelationship.controls['lastName'].touched">
                      <small class="error" *ngIf="addNewDonorFromRelationship.controls['lastName'].errors?.['required']">
                        Last name is required.
                      </small>
                      <small class="error" *ngIf="addNewDonorFromRelationship.controls['lastName'].errors?.['invalidName']">
                        Please enter a valid last name.
                      </small>
                      <small class="error" *ngIf="addNewDonorFromRelationship.controls['lastName'].errors?.['noNumbers']">
                        Numbers aren't allowed.
                      </small>
                    </div>
                  </div>

                  <div class="field col-12">
                    <label class="field-label">Add addresses from the original donor</label>
                    <p-toggleButton name="isSaveAddressSelected" onLabel="Yes" offLabel="No" onIcon="pi pi-check-circle" offIcon="pi pi-ban"
                                    formControlName="isSaveAddressSelected" />
                  </div>
                </div>
              </form>

              <ng-template pTemplate="footer">
                <rc-generic-button label="Cancel" borderStyle="text-only" (click)="clearAddNewDonorDialog()" />
                <rc-generic-button   label="Save" (click)="saveNewDonor($event)" />
              </ng-template>
            </p-dialog>

          </p-card>

          <p-card>
            <div *ngIf="isRelationshipHasErrors" class="block p-error">
              <small class="error" >
                There are some errors in this section. Please correct them before proceeding.
              </small>
            </div>
            <rc-generic-button level="secondary" borderStyle="outline" label="Back" (click)="prevCallback.emit()"
                               [classes]="['mr-1']" />
            <rc-generic-button level="secondary" borderStyle="solid" label="Next" (click)="validateRelationshipAndProceed(nextCallback)" />
          </p-card>

        </form>
      </ng-template>
    </p-stepperPanel>

    <p-stepperPanel header="Additional Data">
      <ng-template pTemplate="content"  let-prevCallback="prevCallback" let-nextCallback="nextCallback" let-index="index">
        <form [formGroup]="form">

          <p-card>
            <div class="grid gap-5">
              <div class="col">
                <div class="formgrid grid">
                  <div class="field col-12 sm:col-6 xl:col-5 xxl:col-4">
                    <label for="donorFlagType" class="field-label">Donor Flag</label>
                    <p-autoComplete id="donorFlagType" formControlName="donorFlagType"
                                    [suggestions]="filteredDonorFlags" (completeMethod)="filterDonorFlags($event)" field="value"
                                    placeholder="Please Select" (onSelect)="addDonorFlag()" />

                    <rc-generic-button label="Add New Type" iconImg="plus" borderStyle="text-only" level="secondary" size="small" [classes]="['mx-1']" (click)="showDialog = true" />

                  </div>
                </div>
              </div>

              <div class="col-12 xl:col-5 xxl:col-4 section-divider" *ngIf="savedDonorFlags.length>0">

                <p-table [value]="savedDonorFlags" styleClass="datatable-sm add-donor-datatable">
                  <ng-template pTemplate="header">
                    <tr>
                      <th>Donor Flags</th>
                      <th></th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-donorFlag>
                    <tr>
                      <td>
                        <span>{{donorFlag.value}}</span>
                      </td>
                      <td>
                        <rc-generic-button label="Delete" iconImg="trash" iconColor="red-light" [isIconOnly]="true"
                          [classes]="['table-action-btn']" (click)="confirmDelete($event, donorFlag, savedDonorFlags, 'Are you sure you want to delete this donorflag?','donorFlag')" />
                      </td>
                    <tr>
                  </ng-template>
                </p-table>

              </div>
            </div>

            <p-dialog [(visible)]="showDialog" [modal]="true" draggable="false" styleClass="p-dialog-static p-dialog-sm" (onHide)="clearNewDonorFlagType()">

              <ng-template pTemplate="header">
                <span class="h3 mb-0 white-space-nowrap">
                  Add New Donor Flag Type
                </span>
              </ng-template>

              <form [formGroup]="editSaveDonorFlag">
                <div class="formgrid grid">
                  <div class="field col-12">
                    <label for="title" class="field-label">Donor Flag Name</label>
                    <input id="title" type="text" pInputText formControlName="title" />
                    <div *ngIf="editSaveDonorFlag.get('title')?.invalid && editSaveDonorFlag.get('title')?.touched">
                      <small id="title-error" class="error">
                        Title is required to proceed.
                      </small>
                    </div>
                  </div>

                  <div class="field col-12">
                    <label for="year" class="field-label">Cycle Year</label>
                    <input id="year" type="text" pInputText placeholder="YYYY" formControlName="year" [maxlength]="4" />

                    <div *ngIf="editSaveDonorFlag.get('year')?.invalid && editSaveDonorFlag.get('year')?.touched">
                      <small id="cycleYear-error" class="error">
                        Year is required to proceed.
                      </small>
                    </div>
                  </div>

                  <div class="field col-12">
                    <label for="description" class="field-label">Flag Description</label>
                    <textarea rows="5" class="w-full p-inputtextarea p-inputtext p-component p-element" pInputTextarea
                              formControlName="description"></textarea>
                    <div *ngIf="editSaveDonorFlag.get('description')?.invalid && editSaveDonorFlag?.get('description')?.touched">
                      <small id="description-error" class="error">
                        Please enter a description.
                      </small>
                    </div>

                  </div>
                </div>
              </form>

              <ng-template pTemplate="footer">
                <rc-generic-button label="Cancel" borderStyle="text-only" (click)="clearNewDonorFlagType()" />
                <rc-generic-button label="Save" (click)="saveNewDonorFlagType()" />
              </ng-template>

            </p-dialog>

          </p-card>

          <p-card>
            <div class="formgrid grid">

              <div class="field  col-12 sm:col-6 md:col-4 xl:col-3 xxl:col-3">
                <label for="informalName" class="field-label">Informal Name</label>
                <input id="informalName" type="text" pInputText formControlName="informalName" />
              </div>

              <div class="field  col-12 sm:col-6 md:col-4 xl:col-3 xxl:col-3">
                <label for="formalSalutation" class="field-label">Formal Salutation</label>
                <input id="formalSalutation" type="text" pInputText formControlName="formalSalutation" />
              </div>

              <div class="field col-12 sm:col-6 md:col-4 xl:col-3 xxl:col-3">
                <label for="mailSalutation" class="field-label">Mail Salutation</label>
                <input id="mailSalutation" type="text" pInputText formControlName="mailSalutation" />
              </div>

            </div>
          </p-card>

          <p-card>
            <div class="formgrid grid">

              <div class="field col white-space-nowrap">
                <p-checkbox [binary]="true" label="Do Not Solicit" formControlName="doNotSolicit" name="doNotSolicit" />
              </div>

              <div class="field col white-space-nowrap">
                <p-checkbox [binary]="true" label="Do Not Mail" formControlName="doNotMail" name="doNotMail" />
              </div>

              <div class="field col white-space-nowrap">
                <p-checkbox [binary]="true" label="Do Not Email" formControlName="doNotEmail" name="doNotEmail" />
              </div>

              <div class="field col white-space-nowrap">
                <p-checkbox [binary]="true" label="Do Not Call" formControlName="doNotCall" name="doNotCall" />
              </div>

              <div class="field col white-space-nowrap">
                <p-checkbox [binary]="true" label="Is Deceased" name="isDeceased" formControlName="isDeceased" />
              </div>
            </div>
          </p-card>
          <p-card>
            <rc-generic-button level="secondary" borderStyle="outline" label="Back" (click)="prevCallback.emit()"
                               [classes]="['mr-1']" />
          </p-card> 
        </form>
      </ng-template>
    </p-stepperPanel>
  </p-stepper>
   
  <p-card styleClass="page-footer">
    <rc-generic-button label="Cancel" borderStyle="text-only" (click)="cancelDialog()" />
    <rc-generic-button label="Save" (click)="save($event)" />
  </p-card>
</div>

<p-dialog [(visible)]="editDialog" [modal]="true" styleClass="p-dialog-static p-dialog-sm" draggable="false">

  <ng-template pTemplate="header">
    <span class="h3 mb-0 white-space-nowrap">Edit {{ getDialogTitle(dialogType) }}</span>
  </ng-template>

  <ng-container *ngIf="dialogType ==='address'">
    <ng-template #dialogHeader>
      <span class="h3 mb-0 white-space-nowrap">Edit Address</span>
    </ng-template>
    <form [formGroup]="editAddressForm">
      <div class="formgrid grid">

        <div class="field col-12">
          <label for="addressTypeId" class="field-label">Address Type</label>
          <p-dropdown id="addressTypeId" formControlName="addressTypeId" [options]="editFilteredAddressTypes" optionLabel="value"
            placeholder="Please Select" optionValue="key" />
        </div>

        <div class="field col-12 sm:col-6 md:col-8 lg:col-9 xl:col-8 xxl:col-9">
          <label class="field-label">Primary Address</label>
          <p-toggleButton name="isPrimary" onLabel="Yes" offLabel="No" onIcon="pi pi-check-circle" offIcon="pi pi-ban"
                          formControlName="isPrimary" />
        </div>

        <div class="field col-12">
          <label for="address1" class="field-label">Address 1</label>
          <input id="address1" type="text" pInputText formControlName="addressLine1" />
          <div *ngIf="isEditAddressFieldsMandate" class="block p-error">
            <small class="error">
              Please enter at least one address field.
            </small>
          </div>
        </div>

        <div class="field col-12">
          <label for="addressLine2" class="field-label">Address 2</label>
          <input id="addressLine2" type="text" pInputText formControlName="addressLine2" />
        </div>

        <div class="field col-12">
          <label for="city" class="field-label">City</label>
          <input id="city" type="text" pInputText formControlName="city" />
        </div>

        <div class="field col-12">
          <label for="stateId" class="field-label">State</label>
          <p-dropdown id="stateId" formControlName="stateId" [options]="states" optionLabel="value"
                      placeholder="Please Select" optionValue="key" />
        </div>

        <div class="field col-12">
          <label for="zipcode" class="field-label">Zip Code</label>
          <input id="zipcode" type="text" pInputText formControlName="zipcode" />
        </div>

        <div class="field col-12">
          <label for="county" class="field-label">County</label>
          <input id="county" type="text" pInputText formControlName="county" />
        </div>

      </div>
    </form>
  </ng-container>

  <ng-container *ngIf="dialogType ==='phoneNumber'">

    <form [formGroup]="editPhoneNumberForm">

      <div class="formgrid grid">
        <div class="field col-12">
          <label for="phoneType" class="field-label">Phone Type</label>
          <p-dropdown id="phoneType" formControlName="phoneType" [options]="editFilteredPhoneTypes" optionLabel="value"
            placeholder="Please Select" optionValue="key" />
        </div>
        <div class="field col-12">
          <label for="phoneNumber" class="field-label">PhoneNumber</label>
          <input id="phoneNumber" type="text" pInputText formControlName="phoneNumber" maxlength="14"
            (input)="formatPhoneNumber($event,'editPhoneNumber')" (keypress)="allowOnlyNumbers($event)" />
          <div *ngIf="editPhoneNumberForm.get('phoneNumber')?.invalid && editPhoneNumberForm?.get('phoneNumber')?.touched">
            <small id="phoneNumber-error" class="error">
              Please enter a valid Phone Number.
            </small>
          </div>
        </div>
      </div>
    </form>

  </ng-container>

  <ng-container *ngIf="dialogType ==='email'">

    <form [formGroup]="editEmailForm">
      <div class="formgrid grid">
        <div class="field col-12">
          <label for="type" class="field-label">Email Type</label>
          <p-dropdown id="type" formControlName="type" [options]="editFilteredEmailTypes" optionLabel="value"
            placeholder="Please Select" optionValue="key" />

          <div *ngIf="editEmailForm.get('type')?.invalid && editEmailForm.get('type')?.touched">
            <small id="type-error" class="error">
              Please select an Email Type to proceed.
            </small>
          </div>
        </div>
        <div class="field col-12">
          <label for="email" class="field-label">Email</label>
          <input id="email" type="email" pInputText formControlName="email" placeholder="" />
          <div *ngIf="editEmailForm.get('email')?.invalid && editEmailForm.get('email')?.touched"
               class="block p-error">
            <small id="email-error" class="error">Please enter a valid Email Address.</small>
          </div>
        </div>
      </div>
    </form>

  </ng-container>

  <ng-container *ngIf="dialogType ==='relationshipType'">
    <form [formGroup]="editRelationshipTypesForm">
      <div>&nbsp;</div>
      <div class="formgrid grid">
        <div class="field col-12">
          <label for="firstName" class="field-label">First Name</label>
          <p-autoComplete id="firstName" formControlName="firstName" [suggestions]="filteredDialogFirstNames"
                          [field]="'text'" placeholder="Please Select" (onSelect)="onDialogFirstNameSelect($event)" />
        </div>

        <div class="field col-12">
          <label for="lastName" class="field-label">Last Name</label>
          <p-autoComplete id="lastName" formControlName="lastName" [suggestions]="filteredDialogLastNames"
                          [field]="'text'" placeholder="Please Select" (onSelect)="onDialogLastNameSelect($event)" />
        </div>

        <div class="field col-12">
          <label for="relationshipTypeId" class="field-label">Relationship Type</label>
          <p-dropdown id="relationshipTypeId" formControlName="relationshipTypeId" [options]="relationshipTypeOptions"
                      optionLabel="text" placeholder="Please Select" optionValue="value" />
        </div>

        <div class="field col-12">
          <label for="isHousehold" class="field-label">Same House Hold</label>
          <p-toggleButton name="isHousehold" onLabel="Yes" offLabel="No" onIcon="pi pi-check-circle"
                          offIcon="pi pi-ban" formControlName="isHousehold" />
        </div>
      </div>
    </form>
  </ng-container>
  <div>&nbsp;</div>

  <ng-template pTemplate="footer">
    <div class="flex pt-1 justify-content-end">
      <rc-generic-button label="Cancel" borderStyle="text-only" (click)="closeDialog()" />
      <rc-generic-button   label="Save" (click)="saveEditItem()" />
    </div>
  </ng-template>

</p-dialog>
