import { Injectable } from "@angular/core";
import { DomHandler } from 'primeng/dom';
import { cleanSVGForOutput } from "../util/util";
import { SearchPageComponent } from "../page/search/search.page.component";
// import { adminRoutes, apexRoutes, vortexFECRoutes, vortexJFCRoutes, vortexLiteRoutes, vortexRoutes } from "../app.routes";
import { expand } from "rxjs";
import { UserModel } from "./MainModel";
import { MenuItem } from "primeng/api";
import App from "./App";


/**
 * The real label for navigation elements is taken from the back end
 * We just we need a user friendly key to match to.
 */
export const Vortex_Navigation:Array<any> = [
    {
        label: 'Campaign Dashboard',
        icon: "fa-sharp fa-light fa-house-flag",
        featureIds: [1],
        route: "vortex/campaign-dashboard",  
        dataCy: "menu-campaign-dashboard"
    },
    {
        label: 'Donors',
        icon: "fa-sharp fa-light fa-users",
        featureIds: [2],
        dataCy: "menu-donors",
        items: [
            {
                label:"Donor Search",
                pageId: 7,
                featureIds: [],
                route:"vortex/donors/donor-search"    
            },
            // TODO: Add New Donor link should be removed 
            // from left nav before release
            {
                label: "Add New Donor",
                pageId: 9,
                featureIds: [],
                route:"vortex/donors/add-new-donor"
            },
            {
                label: "Manual Dedupes",
                pageId: 13,
                featureIds: [3],
                route:"vortex/donors/donor-dedupe"
            },
            {
                label: "Donor Flags",
                pageId: 15,
                featureIds: [4],
                route:"vortex/donors/donor-flags"
            },
            {
                label: "Donor Call Sheets",
                pageId: 18,
                featureIds: [5],
                route:"vortex/donors/donor-call-sheets"
            },
        ]
    },
    {
        label: 'Donations',
        icon: "fa-sharp fa-light fa-money-check-dollar-pen",
        featureIds: [2],
        dataCy: "menu-donations",
        items: [
            {
                label:"Donation Search",
                pageId: 10,
                featureIds: [],
                route:"vortex/donations/donation-search"
            },
            {
                label:"Add New Donation",
                pageId: 19,
                featureIds: [],
                route:"vortex/donations/add-new-donation"
            },
            {
                label:"Batches",
                pageId: 20,
                featureIds: [],
                route:"vortex/donations/donation-batches"
            },
            {
                label:"Donation Flags",
                pageId: 23,
                featureIds: [],
                route:"vortex/donations/donation-flags"
            }
        ]
    },
    {
        label: 'Fundraising Activity',
        icon: "fa-sharp fa-light fa-calendar-days",
        featureIds: [10],
        dataCy: "menu-fundraising-activity",
        items: [
        {
            label:"Fundraisers",
            pageId: 29,
            featureIds: [],
            route:"vortex/fundraising-activity/fundraisers"
        },
        {
            label:"Events",
            pageId: 26,
            featureIds: [],
            route:"vortex/fundraising-activity/events"
        },
        {
            label:"Initiatives",
            pageId: 32,
            featureIds: [],
            route:"vortex/fundraising-activity/initiatives"
        }
        ]
    },
    {
        label: 'Expenses',
        icon: "fa-sharp fa-light fa-chart-pie-simple-circle-dollar",
        pageId: 35,
        featureIds: [12],
        route:"vortex/expenses/expense-search",
        dataCy: "menu-expenses",
    },
    {
        label: 'Reporting Center',
        featureIds: [14],
        icon: "fa-sharp fa-light fa-chart-tree-map",
        dataCy: "menu-reporting-center",
        items: [
            {
                label:"System Reports",
                pageId: 38,
                featureIds: [],
                route:"vortex/reporting-center/system-reports"
            },
            {
                label:"Custom Reports",
                pageId: 38,
                featureIds: [],
                route:"vortex/reporting-center/custom-reports"
            }
        ]
    },
    {
        label: 'FEC',
        icon: "fa-sharp fa-light fa-landmark",
        featureIds: [15],
        pageId: 38,
        dataCy: "menu-fec",
        items: [
            {
                label:"Vortex Filer",
                pageId: 39,
                featureIds: [],
                route:"vortex/fec/vortex-filer"
            },
            {
                label:"FEC Settings",
                pageId: 39,
                featureIds: [],
                route:"vortex/fec/fec-settings"
            },
            {
                label:"FEC Report Cleanup",
                pageId: 39,
                featureIds: [],
                route:"vortex/fec/fec-report-cleanup"
            }
        ]
    },
    {
        label: 'JFC',
        icon: "fa-sharp fa-light fa-split",
        featureIds: [16],
        dataCy: "menu-jfc",
        items: [
            {
                label:"JFC Allocations",
                pageId: 40,
                featureIds: [],
                route:"vortex/jfc/jfc-allocations"
            },
            {
                label:"Distribution Batches",
                pageId: 40,
                featureIds: [],
                route:"vortex/jfc/distribution-batches"
            }
        ]
    }
];
    
export const Admin_Tools: Array<any> = [
    {
        label: 'Donors & Donations',
        icon: "fa-sharp fa-light fa-money-check-dollar-pen",
        // featureIds: [16],
        dataCy: "menu-admin-tools",
        items: [
            {
                label: "Donor Bulk Update",
                pageId: 45,
                featureIds: [7],
                route:"admin/donors-donations/donor-bulk-update"  
            },
            {
                label:"Donation Bulk Upload",
                pageId: 46,
                featureIds: [],
                route:"admin/donors-donations/donation-bulk-upload"
            },
            {
                label:"Compliance Letters",
                pageId: 47,
                featureIds: [],
                route:"admin/donors-donations/compliance-letters"
            }
        ]
    },
    {
        label:"Campaign Settings",
        icon: "fa-sharp fa-light fa-screwdriver-wrench",
        route:"/admin/campaign-settings/campaigns",
        dataCy: "menu-campaign-settings",
        items: [
            {
                label:"Campaign List",
                featureId: -1,
                route:"/admin/campaign-settings/campaign-list"
            },
            {
                label:"Fund Codes",
                featureId: -1,
                route:"/admin/campaign-settings/fund-codes"
            },
            {
                label:"Donation Source Rates",
                featureId: -1,
                route:"/admin/campaign-settings/donation-source-rates"
            },
            {
                label:"Accounting System",
                featureId: -1,
                route:"/admin/campaign-settings/accounting-system"
            },
            {
                label:"Compliance",
                featureId: -1,
                route:"/admin/campaign-settings/compliance"
            },
            {
                label:"Search Throttle",
                featureId: -1,
                route:"/admin/campaign-settings/search-throttle"
            },
            {
                label:"Donor Campaign Limits",
                featureId: -1,
                route:"/admin/campaign-settings/donor-campaign-limits"
            },
            {
                label:"Campaign Types",
                featureId: -1,
                route:"/admin/campaign-settings/campaign-types"
            },
            {
                label:"Default Approver",
                featureId: -1,
                route:"/admin/campaign-settings/default-approver"
            }
        ]
    },
    {
        label:"Organization Settings",
        icon: "fa-sharp fa-light fa-landmark",
        featureId: -1,
        route:"/admin/organization-settings",
        dataCy: "menu-organization-settings",
    },
    {
        label:"Users",
        icon: "fa-sharp fa-light fa-users-gear",
        dataCy: "menu-users",
        items: [
        {
            label:"Invite New User",
            featureId: -1,
            route:"/admin/users/invite-new-user"
        },
        {
            label:"All Users",
            featureId: -1,
            route:"/admin/users/all-users",
        },
        {
            label:"User Bulk Upload",
            featureId: -1,
            route:"/admin/users/user-bulk-upload"
        }
        ]
    },
    {
        label:"System Errors",
        icon: "fa-sharp fa-light fa-message-exclamation",
        items: [
        {
            label:"Login errors",
            featureId: -1,
            route:"/admin/system-errors/login-errors",
        },
        {
            label:"Logout errors",
            featureId: -1,
            route:"/admin/system-errors/logout-errors",
        },
        {
            label:"Transaction Errors",
            featureId: -1,
            route:"/admin/system-errors/transaction-errors"
        }
        ]
    },
];

export const Anonymous_Navigation:Array<any> = [
    {   
        label: 'Select Campaign',
        icon: "fa-sharp fa-light fa-thumbtack",
        featureId: -1,
        pageId: -1,
        route: "campaign-selection",    
        dataCy: "menu-campaign-selection",
    },
];

export const APEX: Array<any>= [
    {
        label:"My APEX Widgets",
        icon: "fa-sharp fa-light fa-cubes",
        pageId: 41,
        featureIds: [19],
        route:"/apex/my-widgets",
        dataCy: "menu-apex-my-widgets",
    },
    {
        label:"APEX Settings",
        icon: "fa-sharp fa-light fa-sliders-up",
        pageId: 42,
        featureIds: [20],
        route:"/apex/apex-settings",
        dataCy: "menu-apex-settings"
    }
];

export const Vortex_Lite: Array<any> = [
    {
        label:"My Fundraising Tally",
        icon: "fa-sharp fa-light fa-gauge-max",
        featureIds: [17],
        pageId: 43,
        route:"vortex-lite/fundraising-tally",
        dataCy: "menu-vortex-lite-tally",
    },
    {
        label:"Events",
        icon: "fa-sharp fa-light fa-calendar-days",
        featureIds: [9],
        pageId: 26,
        route:"vortex-lite/events",
        dataCy: "menu-vortex-lite-events",
    },
    {
        label:"Fundraisers",
        icon: "fa-sharp fa-light fa-megaphone",
        featureIds: [10],
        pageId: 29,
        route:"vortex-lite/fundraisers",
        dataCy: "menu-vortex-lite-fundraisers",
    },
    {
        label:"Donor Flags",
        icon: "fa-sharp fa-light fa-flag",
        featureIds: [4],
        pageId: 15,
        route:"vortex-lite/donor-flags",
        dataCy: "menu-vortex-lite-donorflag",
    },
    {
        label:"Club Settings",
        icon: "fa-sharp fa-light fa-sliders",
        featureIds: [18],
        pageId: 44,
        route:"vortex-lite/club-settings",
        dataCy: "menu-vortex-lite-club",
    }   
]

/**
 * These are pages that arent in the navigation but we still need to map 
 * the route to a feature and page 
 */
export const NonNavigationAccessibity = [
    
    {
        label:"Donor Card",
        featureId: 2,
        pageId: 8,
        route:"vortex/donors",
        showOnNav: false
    },
    {
        label:"Donation Card",
        featureId: 2,
        pageId: 11,
        route: "",
        showOnNav: false
    },
    {
        label:"Edit Donation",
        featureId: 2,
        pageId: 12,
        route: "",
        showOnNav: false
    },
    {
        label:"Donor Bulk Update",
        featureId: 2,
        pageId: 45,
        route: "",
        showOnNav: false
    },
    {
        label:"Compare Donors panel",
        featureId: 3,
        pageId: 14,
        route: "",
        showOnNav: false
    },
    {
        label:"Add Donor Flag",
        featureId: 4,
        pageId: 16,
        route: "",
        showOnNav: false
    },
    {
        label:"Edit Donation Flag",
        featureId: 8,
        pageId: 25,
        route: "",
        showOnNav: false
    },
    {
        label:"Edit Donor Flag",
        featureId: 4,
        pageId: 17,
        route: "",
        showOnNav: false
    },
    {
        label:"Batch Details/Edit Batch",
        featureId: 7,
        pageId: 22,
        route: "",
        showOnNav: false
    },
    {
        label:"Edit Event",
        featureId: 9,
        pageId: 28,
        route: "",
        showOnNav: false
    },
    {
        label:"",
        featureId: -99,
        pageId: -99,
        route: "",
        showOnNav: false
    },
    {
        label:"",
        featureId: -99,
        pageId: -99,
        route: "",
        showOnNav: false
    },
    {
        label:"",
        featureId: -99,
        pageId: -99,
        route: "",
        showOnNav: false
    },
    {
        label:"",
        featureId: -99,
        pageId: -99,
        route: "",
        showOnNav: false
    },
    {
        label:"",
        featureId: -99,
        pageId: -99,
        route: "",
        showOnNav: false
    },
    {
        label:"",
        featureId: -99,
        pageId: -99,
        route: "",
        showOnNav: false
    },
    {
        label:"",
        featureId: -99,
        pageId: -99,
        route: "",
        showOnNav: false
    },
    {
        label:"",
        featureId: -99,
        pageId: -99,
        route: "",
        showOnNav: false
    },
    {
        label:"",
        featureId: -99,
        pageId: -99,
        route: "",
        showOnNav: false
    },
    {
        label:"",
        featureId: -99,
        pageId: -99,
        route: "",
        showOnNav: false
    },
    {
        label:"",
        featureId: -99,
        pageId: -99,
        route: "",
        showOnNav: false
    },
]

export const NavSetsByAppName:Map<string, Array<any>> = new Map();
NavSetsByAppName.set("Vortex3",Vortex_Navigation);
NavSetsByAppName.set("VortexLite", (Vortex_Lite));
NavSetsByAppName.set("Apex", (APEX));
NavSetsByAppName.set("Admin Tools", Admin_Tools);

export const NavOrder = [
    {
        label:"vortex3",
        page:"campaign-dashboard"
    },
    {
        label:"vortexlite",
        page:"fundraising-tally"
    },
    {
        label:"apex",
        page:"my-widgets"
    },
    {
        label:"admin tools", // admins have access to everything
        page:"/vortex/campaign-dashboard"
    }
]

export default class NavModel {
    document: any;
    applicationNavset:Array<any> = [];
    currentState:"closed"|"open" = "closed";
    forwardUseronPageChange:boolean = false;

    // TODO: move somewhere out of the model
    toggleMainNavState:Function = () => {
        this.currentState = this.currentState === "open" ? "closed" : "open" ;

        if (this.currentState === "open") {
            DomHandler.addClass(this.document.body, 'p-overflow-hidden');
        };

        if (this.currentState === "closed") {
            DomHandler.removeClass(this.document.body, 'p-overflow-hidden');
        };
    };
    isShowBreadcrumb:boolean = !false;
}
