
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {AbstractControl, FormControl, Validators} from '@angular/forms';

import { IconField, IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';

import { InputMaskModule } from 'primeng/inputmask';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';  
import { PasswordModule } from 'primeng/password';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { MultiSelectModule } from 'primeng/multiselect';

import { JsonPipe, NgIf } from '@angular/common';
import { StyleClassModule } from 'primeng/styleclass';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';

@Component({
  selector: 'rc-input', 
  standalone: true,
  imports: 
  [
    IconFieldModule, 
    InputIconModule, 
    InputTextModule, 
    FormsModule,
    IconFieldModule, 
    CalendarModule, 
    PasswordModule,
    NgIf,
    InputMaskModule,
    InputTextareaModule,
    AutoCompleteModule,
    MultiSelectModule,
    DropdownModule,
    StyleClassModule,
    FormsModule, 
    ReactiveFormsModule,
  ],
  templateUrl: './generic-text-input.component.html',
  styleUrl: './generic-text-input.component.scss'
})  
export class GenericTextInputComponent implements OnInit{

  @Input({required: false}) label = "";
  @Input({required: false}) placeholder:string | undefined = "";
  @Input({required: false}) isDisabled:boolean = false;
  @Input({required: false}) isEditable:boolean = true;


  // control rendering...
  @Input({required: false}) name: string | undefined = undefined;
  @Input({required: false}) optionLabel: string | undefined = undefined;
  @Input({required: false}) control: any = null; 
  @Input({required: false}) id: string | undefined = undefined; 

  
  @Input({required: true}) type: "text" | "calendar" | "password" | "masked" | "large-text" | "auto-complete" | "select" | "multi-select"  = "text";
  @Input({required: false}) filter: "num" | "money" | "alphabetic" | "alphanumeric"  = "alphanumeric";
  @Input({required: false}) icon: "search" | "date" | "money" | "household" | undefined = undefined ;
  @Input({required: false}) classes:Array<string> = [];
  // if type is set to "masked" this is required...
  @Input({required: false}) mask:string | undefined = undefined;
  @Input({required: false}) selectValues:Array<any> = [];

  // events...
  @Output() valueChange = new EventEmitter<Event>();
  @Output() keyPressUpdate = new EventEmitter<Event>();
  @Output() blur = new EventEmitter<Event>();
  @Output() focus = new EventEmitter<Event>();

  // multi-select...
  @Input({required: false}) display:string = "";
  @Input({required: false}) showClear:boolean = true;

  isValueValid:boolean = true;
  iconClass:string = "";
  errorMessage:string = "";
  isRequired:boolean = false;
  fullClassString:string = "";

  ngOnInit(){
    let validator = this.control?.validator;
    if(validator){
      validator = validator as AbstractControl;
      if (validator && validator.required) {
        this.isRequired = true;
        }
      this.isDisabled === true && this.control?.disable();
    }
    
    switch(this.icon){
      case "search":
        this.iconClass = "pi pi-search";
        break;
      case "date":
        this.iconClass = "pi pi-calendar";
        break;
      case "money":
        this.iconClass = "pi pi-dollar";  
        break;
      case "household":
        this.iconClass = "pi pi-home";
        break;
      }
    
    switch(this.type){
      case "calendar":
        this.icon = "date";
        // this.iconClass = "pi pi-calendar";
        break;
      }

      this.fullClassString = " " + this.classes.join(" ") + " ";
  }


  /**
   * 
   * @param event 
   */
  valueChanged(event:Event){
    this.valueChange.emit(event);
    console.debug("valueChanged", event, this.control?.valid);
    if(!this.control?.valid){
      this.classes.push("ng-invalid");
      this.classes.push("ng-dirty");
    }
  }

  keyPressed(event:Event){
    console.debug("keyPressed", event);
    this.keyPressUpdate.emit(event);
  }

  blurred(event:Event){
    console.debug("blurred", event);
    this.blur.emit(event);
  }

  focussed(event:Event){
    console.debug("focussed", event);
    this.focus.emit(event);    
  }


}