import { Component, Input, OnInit, SimpleChanges, effect } from "@angular/core";
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from "@angular/router";
import { ButtonModule } from "primeng/button";
import { CardModule } from "primeng/card";
import { TableModule } from 'primeng/table';
import { TabViewModule } from "primeng/tabview";
import { DonorCardService } from "../../services/donor-card.service";
import { MainModel } from "../../../global/model/MainModel";
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from "@angular/forms";
import { ChartModule } from 'primeng/chart';
import { DonorFundTotalsGridComponent } from "../../shared-components/donor-fund-totals-grid/donor-fund-totals-grid.component";
import { SidebarModule } from 'primeng/sidebar';
import { DonorAddComponent } from "../donor-add/donor-add.component";
import { DonorFormModel } from "../../models/DonorModel";
import { LoadingSpinnerComponent } from "../../../global/component/loading-spinner/loading-spinner.component";
import { DialogModule } from "primeng/dialog";

@Component({
  selector: 'app-donor-card',
  templateUrl: './donor-card.component.html',
  styleUrls: ['./donor-card.component.scss'],
  standalone: true,
  imports: [FormsModule, TabViewModule, CardModule, ButtonModule,
    CommonModule, TableModule, CheckboxModule, ChartModule,
    DonorFundTotalsGridComponent, SidebarModule, DonorAddComponent,
    LoadingSpinnerComponent, DialogModule],
  providers: [DonorCardService]
})
export class DonorCardComponent implements OnInit {
  donorId: string;
  headerData: any;
  generalInfo: any;
  primaryAddress: any;
  phoneNumber: any;
  emailAddress: any;
  expandedRows = {};
  donorLetters = [];
  donorCallsheetsLog = [];
  donorNotes = [];
  donorFlags = [];
  donorRelationships = [];
  donorSalutations: any = {};
  donorSuppression: any = {};
  donorCurrentCycleDonations: any;
  donorCurrentCycleDonationsChartData: any;
  donorCurrentCycleDonationsChartOptions: any;
  donorLifetimeHistory: any;
  donorLifetimeHistoryChartData: any;
  donorLifetimeHistoryChartOptions: any;

  tabIndex = 0;
  activeTab = 0;
  donorToEdit: DonorFormModel | null = null;
  editStep = 0;
  @Input({ required: true }) isDockable: boolean = true;
  isDonorEditSidebarVisible: boolean = false;
  isDonorEditDataLoading: boolean = false;
  @Input() isModalPopup: boolean = false;
  @Input() fromSearchDonorID: any;

  constructor(private route: ActivatedRoute,
    private mainModel: MainModel,
    private donorCardService: DonorCardService) {
     this.donorId = this.route.snapshot.paramMap.get('id') ?? '';

    effect(() => {
      this.loadData();
    });
  }

  loadData(){
    this.loadGeneralInformation();
    this.loadContactInformation();
    this.loadDonorLetters();
    this.loadDonorCallsheetsLog();
    this.loadDonorNotes();
    this.loadDonorFlags();
    this.loadDonorRelationships();
    this.loadDonorSalutations();
    this.loadDonorSuppression();
    this.loadCurrentCycleDonations();
    this.loadLifetimeHistory();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['fromSearchDonorID']) {
      this.donorId = this.fromSearchDonorID ? this.fromSearchDonorID : this.route.snapshot.paramMap.get('id') ?? '';
      
      if(this.donorId){
        this.loadData();
      }
    }
  }

  switchTab(selectedTab: any) {
    this.activeTab = selectedTab.index;
    this.editStep = -1;

    switch (this.activeTab) {
      case 0: { // General
        this.editStep = 0;
        // this.loadGeneralInformation();
        break;
      }
      case 1: { // Contact
        this.editStep = 1;
        // this.loadContactInformation();
        break;
      }
      case 2: { // Donor letters
        // this.loadDonorLetters();
        break;
      }
      case 5: { // Donor flags
        this.editStep = 3;
        break;
      }
      case 6: { //Salutation
        this.editStep = 3;
        break;
      }
      case 7: { // Supression
        this.editStep = 3;
        break;
      }
    }
  }

  // general-info endpoint
  loadGeneralInformation(): void {
    if (this.donorId !== '') {
      this.donorCardService.getDonorCardGeneralInfo(parseInt(this.donorId)).subscribe({
        next: data => {
          console.debug(data);
          this.generalInfo = data.generalInformation;
          this.headerData = data.donorCardHeader;
        },
        error: err => {
          console.error('Unable to retrieve general information. Please try again later.', err);
        },
        complete: () => console.debug("general-info service call complete")
      });
    }
  }

  loadContactInformation(): void {
    if (this.donorId !== '') {
      this.donorCardService.getDonorCardContactInfo(parseInt(this.donorId)).subscribe({
        next: data => {
          console.debug(data);
          this.primaryAddress = data.addresses.filter((item: any) => item.isPrimaryString === "Yes")[0];
          this.phoneNumber = (data.phones.length > 0) ? data.phones.filter((i: any) => i.phoneTypeId === 1)[0].donorPhoneNumber : "";
          this.emailAddress = (data.emails.length > 0) ? data.emails.filter((i: any) => i.emailTypeId === 1)[0].email : "";
        },
        error: err => {
          console.error('Unable to retrieve contact information. Please try again later.', err);
        },
        complete: () => console.debug("contact-info service call complete")
      });
    }
  }

  loadDonorLetters(): void {
    if (this.donorId !== '') {
      const c: any = this.mainModel.selectedCampaign();
      this.donorCardService.getDonorCardDonorLetters(parseInt(c?.campaignId), parseInt(this.donorId)).subscribe({
        next: data => {
          console.debug(data);
          this.donorLetters = data.selectedLetterCommunications;
        },
        error: err => {
          console.error('Unable to retrieve donor letters. Please try again later.', err);
        },
      });
    }
  }

  loadDonorCallsheetsLog(): void {
    if (this.donorId !== '') {
      const c: any = this.mainModel.selectedCampaign();
      this.donorCardService.getDonorCardCallsheetsLog(parseInt(c?.campaignId), parseInt(this.donorId)).subscribe({
        next: data => {
          console.debug(data);
          this.donorCallsheetsLog = data;
        },
        error: err => {
          console.error('Unable to retrieve donor callsheets log. Please try again later.', err);
        },
      });
    }
  }

  loadDonorNotes(): void {
    if (this.donorId !== '') {
      this.donorCardService.getDonorNotes(parseInt(this.donorId)).subscribe({
        next: data => {
          console.debug(data);
          this.donorNotes = data;
        },
        error: err => {
          console.error('Unable to retrieve donor notes. Please try again later.', err);
        },
      });
    }
  }

  loadDonorFlags(): void {
    if (this.donorId !== '') {
      this.donorCardService.getDonorFlags(parseInt(this.donorId)).subscribe({
        next: data => {
          console.debug(data);
          this.donorFlags = data;
        },
        error: err => {
          console.error('Unable to retrieve donor flags. Please try again later.', err);
        },
      });
    }
  }

  loadDonorSalutations(): void {
    if (this.donorId !== '') {
      this.donorCardService.getDonorSalutations(parseInt(this.donorId)).subscribe({
        next: data => {
          console.debug(data);
          this.donorSalutations = data;
        },
        error: err => {
          console.error('Unable to retrieve donor salutations. Please try again later.', err);
        },
      });
    }
  }

  loadDonorSuppression(): void {
    if (this.donorId !== '') {
      this.donorCardService.getDonorSuppression(parseInt(this.donorId)).subscribe({
        next: data => {
          console.debug(data);
          this.donorSuppression = data;
        },
        error: err => {
          console.error('Unable to retrieve donor suppression data. Please try again later.', err);
        },
      });
    }
  }

  loadDonorRelationships(): void {
    if (this.donorId !== '') {
      this.donorCardService.getDonorRelationships(parseInt(this.donorId)).subscribe({
        next: data => {
          console.debug(data);
          this.donorRelationships = data;
        },
        error: err => {
          console.error('Unable to retrieve donor relationships. Please try again later.', err);
        },
      });
    }
  }

  ngOnInit(): void {
    
  }

  getRemainingFlag(remaining: string) {
    if (parseFloat(remaining) < 0) {
      return 'danger';
    }
    return null;
  }


  loadCurrentCycleDonations(): void {
    const c: any = this.mainModel.selectedCampaign();
    this.donorCardService.getCurrentCycleDonations(parseInt(c?.campaignId), parseInt(this.donorId)).subscribe({
      next: data => {
        console.debug(data);
        this.donorCurrentCycleDonations = data;

        this.donorCurrentCycleDonationsChartData = {
          // labels: this.donorCurrentCycleDonations.donations.map((ds: any) => ds.donationId),
          labels: this.donorCurrentCycleDonations.donations.map((ds: any) => ds.donationId),
          datasets: [
            {
              data: this.donorCurrentCycleDonations.donations.map((ds: any) => ds.amount),
              // backgroundColor: ['rgb(249, 115, 22)', 'rgb(6, 182, 212)', 'rgb(107, 114, 128)', 'rgb(139, 92, 246)'],
              // hoverBackgroundColor: ['red', 'blue', 'violet', 'purple']
            }
          ]
        };

        this.donorCurrentCycleDonationsChartOptions = {
          plugins: {
            legend: {
              labels: {
                usePointStyle: true
              }
            }
          }
        };
      },
      error: err => {
        console.error('Unable to retrieve current cycle donations. Please try again later.', err);
      }
    })
  }

  loadLifetimeHistory(): void {
    const c: any = this.mainModel.selectedCampaign();
    this.donorCardService.getLifetimeHistory(parseInt(c?.campaignId), parseInt(this.donorId)).subscribe({
      next: data => {
        console.debug(data);
        this.donorLifetimeHistory = data;

        this.donorLifetimeHistoryChartData = {
          labels: this.donorLifetimeHistory.donationsBySource.map((ds: any) => ds.source),
          datasets: [
            {
              data: this.donorLifetimeHistory.donationsBySource.map((ds: any) => ds.amount),
              // backgroundColor: ['rgb(249, 115, 22)', 'rgb(6, 182, 212)', 'rgb(107, 114, 128)', 'rgb(139, 92, 246)'],
              // hoverBackgroundColor: ['red', 'blue', 'violet', 'purple']
            }
          ]
        };

        this.donorLifetimeHistoryChartOptions = {
          plugins: {
            legend: {
              labels: {
                usePointStyle: true
              },
              position: 'right'
            }
          }
        };
      },
      error: err => {
        console.error('Unable to retrieve donor lifetime history. Please try again later.', err);
      }
    })
  }

  editDonor() {
    this.isDonorEditSidebarVisible = true;
    this.isDonorEditDataLoading = true;
    this.loadDonorDataForEdit();
  }

  loadDonorDataForEdit(): void {    
    if (this.donorId !== '') {
      this.donorCardService.getDonorForEdit(parseInt(this.donorId)).subscribe({
        next: data => {
          console.debug(data);
          this.isDonorEditDataLoading = false;
          this.donorToEdit = data;
        },
        error: err => {
          console.error('Unable to retrieve donor data for editing. Please try again later.', err);
        },
      });
    }
  }

  closeModal() {
    this.isDonorEditSidebarVisible = false;
  }

}
