<div id="donor-card" data-cy="donor-card">
  <h1>Donor Card</h1>


  <p-tabView styleClass="tabbed-card" (onChange)="switchTab($event)">

    <div class="grid flex-nowrap justify-content-between mx-3" data-cy="donor-header">
      <!-- hiding on mobile to save space -->
      <div class="col hidden sm:flex align-items-center">
        <!-- Conditional -->
        <i class="fa-sharp fa-light fa-circle-user content-type-icon"></i>
        <!-- <i class="fa-sharp fa-light fa-landmark content-type-icon"></i> -->
      </div>
      <div class="col flex align-items-center" style="flex-grow: 6;" *ngIf="headerData != null" data-cy="donor-info">
        <div class="">
          <p class="text-xl md:text-2xl font-bold red-60">{{ headerData.prefix ? headerData.prefix + '.' : '' }} {{ headerData.firstName }} {{ headerData.lastName }} {{ headerData.suffix ? headerData.suffix + '.' : '' }}</p>
          <p class="mb-0">{{ headerData.donorId }}</p>
          <p>{{ headerData.city }}{{ headerData.state ? ', ' + headerData.state : '' }}</p>
        </div>
      </div>
      <div class="col flex align-items-center justify-content-end">
        <p-button styleClass="p-button-icon-teal-light mx-1"
                  icon="fa-sharp fa-light fa-pencil"
                  [rounded]="true"
                  (onClick)="editDonor()"
                  title="Edit"
                  data-cy="edit-donor-button"
                  *ngIf="editStep >= 0" />

      </div>
    </div>

    <p-tabPanel header="General">

      <hr class="divider-dashed mt-0 mb-5">

      <div class="grid mx-3" *ngIf="generalInfo != null">
        <div class="col-6">
          <p><b>Employer: </b>{{ generalInfo.employer }}</p>
          <p><b>Occupation: </b>{{generalInfo.occupation}}</p>
          <p><b>DOB: </b>{{generalInfo.dateOfBirth}}</p>
          <p><b>Inception: </b> </p>
        </div>
        <div class="col-6">
          <p><b>Fundraiser: </b><!-- generalInfo.fecCandidateId}} --> </p>
          <p><b>Legacy ID: </b>{{generalInfo.legacyRecordId}}</p>
          <p><b>FEC Candidate ID: </b> <!-- generalInfo.fecCandidateId}} --></p>
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel header="Contact">
      <hr class="divider-dashed mt-0 mb-5">

      <div class="grid mx-3" *ngIf="primaryAddress != null">
        <div class="col-6">
          <p><b>Address: </b>{{primaryAddress.address1}} {{primaryAddress.address2}}</p>
          <p><b>City: </b>{{primaryAddress.city}}</p>
          <p><b>State: </b>{{primaryAddress.state}}</p>
          <p><b>Primary: </b>{{primaryAddress.isPrimaryString}}</p>
        </div>
        <div class="col-6">
          <p><b>Home Phone: </b>{{phoneNumber}} </p>
          <p><b>Primary Email: </b> {{emailAddress}}</p>
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel header="Donor Letters">
      <hr class="divider-dashed mt-0 mb-5">
      <div class="col-12">
        <p-table [value]="donorLetters" dataKey="id" [tableStyle]="{ 'min-width': '5rem' }"
                 [expandedRowKeys]="expandedRows" [scrollable]="true" scrollHeight="400px">

          <ng-template pTemplate="header">
            <tr>
              <th>Letter Type</th>
              <th>Date Sent</th>
              <th>Response Date</th>
              <th></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-donorLetterItem let-expanded="expanded">
            <tr>
              <td>{{ donorLetterItem.letterType }}</td>
              <td>{{ donorLetterItem.dateSent | date : 'M/d/yyyy' }}</td>
              <td>{{ donorLetterItem.responseReceivedDate | date : 'shortDate' }}</td>
              <td>
                <p-button styleClass="p-button-icon-teal-light mx-1" icon="fa-sharp fa-light fa-pencil"
                          [rounded]="true" title="Delete" />
                <p-button styleClass="p-button-icon-red-light mx-1" icon="fa-sharp fa-light fa-trash-can"
                          [rounded]="true" title="Delete" />
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" *ngIf="donorLetters.length==0">
            <tr>
              <td [attr.colspan]="4" style="text-align: center;">
                No records found. Try modifying your search criteria.
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>

    </p-tabPanel>
    <p-tabPanel header="Call Sheets Log">
      <hr class="divider-dashed mt-0 mb-5" />
      <div class="col-12">
        <p-table [value]="donorCallsheetsLog" dataKey="id" [tableStyle]="{ 'min-width': '5rem' }"
                 [expandedRowKeys]="expandedRows" [scrollable]="true" scrollHeight="400px">

          <ng-template pTemplate="header">
            <tr>
              <th>Call Date</th>
              <th>Call Answered</th>
              <th>Email Sent</th>
              <th>Initiated by</th>
              <th>Notes</th>
              <th></th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-donorCallsheetItem let-expanded="expanded">
            <tr>
              <td>{{ donorCallsheetItem.callDate | date : 'M/d/yyyy' }}</td>
              <td>{{ donorCallsheetItem.callAnswered }}</td>
              <td>{{ donorCallsheetItem.emailSent }}</td>
              <td>{{ donorCallsheetItem.initiatedBy }}</td>
              <td>{{ donorCallsheetItem.note }}</td>
              <td>
                <p-button styleClass="p-button-icon-teal-light mx-1" icon="fa-sharp fa-light fa-gear"
                          [rounded]="true" title="Options" />
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" *ngIf="donorCallsheetsLog.length==0">
            <tr>
              <td [attr.colspan]="6" style="text-align: center;">
                No records found. Try modifying your search criteria.
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </p-tabPanel>
    <p-tabPanel header="Notes">
      <hr class="divider-dashed mt-0 mb-5">
      <div class="col-12">
        <p-table [value]="donorNotes" dataKey="id" [tableStyle]="{ 'min-width': '5rem' }"
                 [expandedRowKeys]="expandedRows" [scrollable]="true" scrollHeight="400px">

          <ng-template pTemplate="header">
            <tr>
              <th>Date</th>
              <th>Note</th>
              <th></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-donorNoteItem let-expanded="expanded">
            <tr>
              <td>{{ donorNoteItem.createdDate | date : 'M/d/yyyy' }}</td>
              <td>{{ donorNoteItem.note }}</td>
              <td>
                <p-button styleClass="p-button-icon-teal-light mx-1" icon="fa-sharp fa-light fa-pencil"
                          [rounded]="true" title="Delete" />
                <p-button styleClass="p-button-icon-red-light mx-1" icon="fa-sharp fa-light fa-trash-can"
                          [rounded]="true" title="Delete" />
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" *ngIf="donorNotes.length==0">
            <tr>
              <td [attr.colspan]="4" style="text-align: center;">
                No records found. Try modifying your search criteria.
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </p-tabPanel>
    <p-tabPanel header="Donor Flags">

      <hr class="divider-dashed mt-0 mb-5" />
      <div class="col-12">
        <p-table [value]="donorFlags" dataKey="id" [tableStyle]="{ 'min-width': '5rem' }"
                 [expandedRowKeys]="expandedRows" [scrollable]="true" scrollHeight="400px">

          <ng-template pTemplate="header">
            <tr>
              <th>Donor Flags</th>
              <th></th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-donorFlagItem let-expanded="expanded">
            <tr>
              <td>{{ donorFlagItem.name }}</td>
              <td>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" *ngIf="donorFlags.length==0">
            <tr>
              <td [attr.colspan]="6" style="text-align: center;">
                No records found. Try modifying your search criteria.
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </p-tabPanel>
    <p-tabPanel header="Salutation">
      <hr class="divider-dashed mt-0 mb-5">
      <div class="grid mx-3">
        <div class="col-6">
          <p><b>Informal: </b>{{donorSalutations?.informalName}}</p>
          <p><b>Formal: </b>{{donorSalutations?.formalSalutation}}</p>
          <p><b>Mail: </b>{{donorSalutations?.mailSalutation}}</p>
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel header="Supression">
      <hr class="divider-dashed mt-0 mb-5">
      <div class="grid mx-3">
        <div class="col-6">
          <p>
            <p-checkbox
                        [(ngModel)]="donorSuppression.doNotSolicit"
                        [binary]="true"
                        inputId="chkDoNotSolicit"
                        label="Do Not Solicit" />
          </p>
          <p>
            <p-checkbox
                        [(ngModel)]="donorSuppression.doNotEmail"
                        [binary]="true"
                        inputId="chkDoNotSolicit"
                        label="Do Not Email" />
          </p>
          <p>
            <p-checkbox
                        [(ngModel)]="donorSuppression.doNotMail"
                        [binary]="true"
                        inputId="chkDoNotMail"
                        label="Do Not Mail" />
          </p>
          <p>
            <p-checkbox
                        [(ngModel)]="donorSuppression.doNotCall"
                        [binary]="true"
                        inputId="chkDoNotCall"
                        label="Do Not Call" />
          </p>
          <p>
            <p-checkbox
                        [(ngModel)]="donorSuppression.isDeceased"
                        [binary]="true"
                        inputId="chkIsDeceased"
                        label="Is Deceased" />
          </p>
        </div>
      </div>
    </p-tabPanel>
  </p-tabView>

  <div class="mb-5"></div>

  <div class="grid">
    <div class="col-12 sm:col-6 lg:col-3">

      <p-card styleClass="card-style-donor">
        <ng-template pTemplate="header">
          <p>Current Cycle</p>
          <p-button icon="pi pi-window-maximize"
                    [text]="true"
                    title="Expand" />
        </ng-template>
        <div class="grid">
          <div class="card">
            <p><b>Current Cycle Fund Code:</b></p>
            <p><b>Total: </b>{{donorCurrentCycleDonations?.total | currency}}</p>
            <p><b>Count: </b>{{donorCurrentCycleDonations?.count}}</p>
            <p-chart type="bar" [data]="donorCurrentCycleDonationsChartData" [options]="donorCurrentCycleDonationsChartOptions" width="100%" height="250px"/>
          </div>
        </div>
      </p-card>

    </div>

    <div class="col-12 sm:col-6 lg:col-3">

      <p-card styleClass="card-style-donor">
        <ng-template pTemplate="header">
          <p>Lifetime History</p>
          <p-button icon="pi pi-window-maximize"
                    [text]="true"
                    title="Expand" />
        </ng-template>
        <div class="grid">
          <div class="col">
            <p><b>Total: </b>{{donorLifetimeHistory?.total | currency}}</p>
            <p><b>Count: </b>{{donorLifetimeHistory?.count}}</p>
            <p-chart type="pie" [data]="donorLifetimeHistoryChartData" [options]="donorLifetimeHistoryChartOptions" class="w-full md:w-[30rem]" width="100%"/>
          </div>
        </div>
      </p-card>

    </div>

    <div class="col-12 sm:col-6 lg:col-6">

      <p-card styleClass="card-style-donor">
        <ng-template pTemplate="header">
          <p>Fund Totals</p>
          <p-button icon="pi pi-window-maximize"
                    [text]="true"
                    title="Expand" />
        </ng-template>
        <div class="grid">
          <div class="col-12">
            <app-donor-fund-totals-grid [donorId]="donorId">
            </app-donor-fund-totals-grid>
          </div>
        </div>
      </p-card>

    </div>

    <div class="col-12 sm:col-6 lg:col-6">
      <p-card styleClass="card-style-donor">
        <ng-template pTemplate="header">
          <p>Relationships</p>
          <p-button icon="pi pi-window-maximize"
                    [text]="true"
                    title="Expand" />
        </ng-template>
        <div class="grid">
          <div class="col-12">
            <p-table [value]="donorRelationships"
                     dataKey="id"
                     [tableStyle]="{ 'min-width': '5rem' }"
                     [expandedRowKeys]="expandedRows"
                     [scrollable]="true"
                     scrollHeight="400px">

              <ng-template pTemplate="header">
                <tr>
                  <th>Name</th>
                  <th>Relationship</th>
                  <th></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-donorRelationshipItem let-expanded="expanded">
                <tr>
                  <td>{{ donorRelationshipItem.relatedDonorName }}</td>
                  <td>{{ donorRelationshipItem.relationshipTypeName }}</td>
                  <td></td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </p-card>
    </div>
  </div>
</div>

<div id="edit-donor-card" data-cy="edit-donor-card">
  <p-dialog 
    header="Edit Donor" 
    [modal]="true"
    [(visible)]="isDonorEditSidebarVisible"
    [maximizable]="true"
    styleClass="p-dialog-static p-dialog-lg fadeinright animation-duration-300" >

    <ng-template pTemplate="header">
      <div class="modal-header">
        <span class="h3 mb-0 white-space-nowrap">
          Edit Donor
        </span>
      </div>
    </ng-template>

    <div>
      <div ngClass="medium loader-wrapper" *ngIf="isDonorEditDataLoading">
        <rc-loading-spinner></rc-loading-spinner>
      </div>

      <app-donor-add  [isModalPopup]="true" 
                      [donorToEdit]="donorToEdit"
                      [isEditMode] = "true"
                      [activeStep] = "editStep"
                      *ngIf="!isDonorEditDataLoading"
                      (closeEditModal) = "closeModal()">
      </app-donor-add>
    </div>

  </p-dialog>
</div>
