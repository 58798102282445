import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Table, TableModule, TablePageEvent } from 'primeng/table';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { FormsModule } from '@angular/forms';
import { MenuModule } from 'primeng/menu';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { MultiSelectModule } from 'primeng/multiselect';
import { Observable } from 'rxjs/internal/Observable';
import { ToggleButtonModule } from 'primeng/togglebutton';
import PagingModel from '../../model/PagingMoel';
import { SearchModel, DisplayColumn, LookupItem, LookupFilter } from '../../model/SearchModel';
import { MenuItem, MessageService, SelectItem } from 'primeng/api';
import { SearchService } from '../../services/data/SearchService/searchservice';
import { GenericButtonComponent } from "../../../global/component/generic/generic-button/generic-button.component";
import { CardModule } from 'primeng/card';
import { ToastModule } from 'primeng/toast';
import { AddDonationService } from '../../../vortex/services/add-donation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TagModule } from 'primeng/tag';
import { InputIconModule } from 'primeng/inputicon';
import { InputGroupModule } from 'primeng/inputgroup';
import { DOCUMENT } from '@angular/common';
import { IconFieldModule } from 'primeng/iconfield';
import { GenericTextInputComponent } from "../../../global/component/generic/generic-text-input/generic-text-input.component";
import { LoadingSpinnerComponent } from "../loading-spinner/loading-spinner.component";
import { OverlayPanelModule } from 'primeng/overlaypanel';
import {  FormControl} from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { DonorCardComponent } from '../../../vortex/page/donor-card/donor-card.component';

interface ExportColumn {
  title: string;
  dataKey: string;
}


/**
 * This component should take in a set of default colmns to show. 
 * These may be:
 * 1. Hard coded from the developer based on the page or user type
 * 2. injected from saved database preferences
 * 
 * Data Clumns:
 * Injected data fields should also match the columns passed in. 
 * 
 * 
 * Exported columns:
 * We may eventually want to control which fileds will be outut into omething like a print job - but for now we dont have a UI designed for this.
 */
@Component({
  selector: 'app-search',
  standalone: true,
  imports: [TableModule, CommonModule, ButtonModule, DialogModule, FormsModule, MultiSelectModule, ToggleButtonModule, MenuModule, GenericButtonComponent, ToastModule, 
     DonorCardComponent, TagModule, InputGroupModule, InputIconModule, IconFieldModule, CardModule, GenericTextInputComponent, LoadingSpinnerComponent, OverlayPanelModule],
  providers: [MessageService, AddDonationService],
  templateUrl: './search.component.html',
  styleUrl: './search.component.scss'
})
export class SearchComponent implements OnInit {
  @Output() cancelEvent = new EventEmitter<void>();

  @ViewChild('resultstable')
  resultstable!: Table;
  dataObject!: Observable<unknown>;
  dataElements: Array<any> = [];
  isFullscreen: boolean = false;
  cols!: DisplayColumn[];
  exportColumns: ExportColumn[] = [];
  selectedStates: string[] = [];
  selectedFundCodes: number[] = [];
  selectedStatus: string[] = [];
  selectedApproval: string[] = [];
  defaultColumns: DisplayColumn[] = [];
  groupedOptions: any[] = [];
  selectedColumns: DisplayColumn[] = [];
  defaultCmmonFilters: SelectItem[] = [];
  defaultTextFilter: SelectItem[] = [];
  defaultAmountFilter: SelectItem[] = [];
  states: LookupItem[] = [];
  fundCodes: LookupItem[] = [];
  statuses: LookupItem[] = [];
  approvals: LookupItem[] = [];
  rawData: any[] = [];
  totalData: any[] = [];
  approvalsData: any[] = [];
  exportOptions: MenuItem[] = [];
  selectedOptions: MenuItem[] = [];
  isExpanded = false;
  maxSelectedLabels = this.isExpanded ? 100 : 10;
  // expandIcon:any = PrimeIcons.EXPAND;
  balanceFrozen: boolean = true;
  showScrollFilters: boolean = false;
  filters: Array<string> = [];
  selectedRows: any[] = [];
  showLoading: boolean = false;
  searchClicked: boolean = false;
  @Input() showInDialog: boolean = false;
  @Input() selectedSearchArea: any = null;
  @Input() searchKeyword: string = '';
  filterValue: FormControl = new FormControl('');
  visibleDonorGivingHistory: boolean = false;
  hideSearchControls: boolean = false;
  showDialog: boolean = false;
  selectedDonorId :any;

  //inputs...
  @Input({ required: true }) pagingModel: PagingModel = new PagingModel();
  @Input({ required: true }) columnData: SearchModel = { displayColumns: [], states: [], fundCodes: [], commonFilters: [], textFilters: [], amountFilters: [], statusOptions: [], approvalOptions: [] };

  // outputs
  @Output() dataViewUpdate = new EventEmitter<PagingModel>();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private servicesearch: SearchService,
    private messageService: MessageService,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {

  }

  hasNoResults = () => !this.rawData || this.rawData.length === 0;

  scrollToTop() {
    // this.showScrollFilters = !this.showScrollFilters;
  }

  resetFilters() {
    this.resultstable.clear();
    this.selectedStates = [];
    this.selectedFundCodes = [];
    this.rawData = [];
    this.searchClicked = false;
  }

  pageForward() {
    (this.document.getElementsByClassName("p-paginator-next")[0] as HTMLAnchorElement).click()
  }

  pageBack() {
    (this.document.getElementsByClassName("p-paginator-prev")[0] as HTMLAnchorElement).click()
  }

  toggleSelectedLabels() {
    this.isExpanded = !this.isExpanded;
    this.maxSelectedLabels = this.isExpanded ? 100 : 10;
    this.selectedColumns = [...this.selectedColumns];
  }

  updatePagingModel(start: number, maxResults: number = 10) {
    const pModel: PagingModel = this.pagingModel;
    pModel.maxResults = maxResults;
    pModel.startRow = start;
    // we emit and the service call should inject a new model...
    this.dataViewUpdate.emit(pModel);
  }

  pageChange(event: TablePageEvent) {
    this.updatePagingModel(event.first, event.rows);
  }

  ngOnInit() {
    this.filterValue.setValue(this.searchKeyword);
    if(this.searchKeyword)
      this.hideSearchControls= true;

    this.exportOptions = [
      { label: 'Export to CSV', icon: 'pi pi-file', command: () => this.onExportToCSV() },
      { label: 'Export to Excel', icon: 'pi pi-file-excel', command: () => this.onExportToExcel() }
    ];
    this.selectedOptions = [
      { label: 'Approve my Expenses', icon: 'fa-sharp fa-light fa-user-check', command: () => "" },
      { label: 'Send for Payment', icon: 'fa-sharp fa-light fa-envelope-open-dollar', command: () => "" },
      { label: 'Delete', icon: 'fa-sharp fa-light fa-trash-can', command: () => "" }
    ];
    this.initializeColumns();
  }

  ngAfterViewInit() {
    if (this.isExpense() || this.searchKeyword) {
      this.onSearch();
      this.cdr.detectChanges();
    }
  }
  ngOnChanges() {
    this.initializeColumns();
  }

  onPageSearch(){
    this.onSearch();
    this.cdr.detectChanges();
  }

  private initializeColumns() {
    this.defaultColumns = this.columnData && this.columnData.displayColumns ? this.columnData.displayColumns : [];
    if (this.defaultColumns)
      this.groupedOptions = this.groupColumnsBy('groupByColumn');
    this.selectedColumns = this.columnData && this.columnData.displayColumns ? this.columnData.displayColumns.filter(x => x.isDefault) : [];
    this.defaultCmmonFilters = this.columnData && this.columnData.commonFilters ? this.convertLookupItemsToSelectItems(this.columnData.commonFilters) : [];
    this.defaultTextFilter = this.columnData && this.columnData.textFilters ? this.convertLookupItemsToSelectItems(this.columnData.textFilters) : [];
    this.defaultAmountFilter = this.columnData && this.columnData.amountFilters ? this.convertLookupItemsToSelectItems(this.columnData.amountFilters) : [];
    this.states = this.columnData && this.columnData.states || [];
    this.fundCodes = this.columnData && this.columnData.fundCodes || [];
    this.statuses = this.columnData && this.columnData.statusOptions || [];
    this.approvals = this.columnData && this.columnData.approvalOptions || [];
    this.cols = this.selectedColumns || [];
    this.exportColumns = this.cols.map((col: any) => ({ title: col.header, dataKey: col.field }));
  }

  private convertLookupItemsToSelectItems(lookupItems: LookupFilter[]): SelectItem[] {
    return lookupItems.map((item: LookupFilter) => ({ value: item.value, label: item.label }));
  }

  groupColumnsBy(groupByField: keyof DisplayColumn) {
    const groups: any = {};
    this.defaultColumns.forEach(column => {
      const group = column[groupByField] as string;
      if (!groups[group]) {
        groups[group] = [];
      }
      groups[group].push(column); // Pass entire DisplayColumn object as value
    });

    return Object.keys(groups).map(group => ({
      label: group,
      items: groups[group]
    }));
  }

  getMatchMode(columnType: string): string {
    if (columnType === "TextFilter" || columnType === "IDFilter")
      return 'equals';
    if (columnType === "AmountFilter")
      return 'gt';
    else
      return 'startsWith'
  }

  restrictNonNumeric(event: KeyboardEvent) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault(); // Prevent non-numeric input
    }
  }
  //#region onSearch Click
  onSearch() {
    this.showLoading = true;
    if (this.checkFilters()) {
      this.searchClicked = true;
      // Collect the current filters from the p-table
      const filterData = this.buildFilterData(this.resultstable.filters);
      const campaignId = 406; // Replace with your campaignId
      // Make the API call to generate the report
      this.loadFilteredData(campaignId, filterData);
    }
    else {
      this.showLoading = false;
      this.searchClicked = false;
    }
  }
  private checkFilters(): boolean {
    if (this.isDonor() || this.isDonation()) {
      let filterSelected = false;
      for (let col of this.selectedColumns) {
        const filterKey = col.columnName;
        if (this.resultstable.filters[filterKey]) {
          const filter = this.resultstable.filters[filterKey];
          if (Array.isArray(filter)) {
            for (let filterItem of filter) {
              if (filterItem && filterItem.value && filterItem.value !== '') {
                filterSelected = true;
                break;
              }
            }
          } else if (filter && filter.value && filter.value !== '') {
            filterSelected = true;
            break;
          }
        }
      }

      if (!this.searchKeyword) {
        if (!filterSelected ) {
          // If no filters are selected, show the toast error
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Please select at least one filter before searching.',
          });
        }
      }
      else {
        return true;
      }
      return filterSelected;
    }
    return true;
  }

  private buildFilterData(filters: { [key: string]: any }): string {
    if (this.selectedStates && this.selectedStates.length > 0) {
      filters['states'] = {
        values: this.states
          .filter(state => this.selectedStates.includes(state.value))
          .map(state => state.key)
      };
    }
    else {
      filters['states'] = { values: [] };
    }
    var selectedfunds = this.selectedColumns.filter(column => column.groupByColumn === "Fund Codes")
    if (selectedfunds && selectedfunds.length > 0) {
      filters['fundcodes'] = {
        values: this.selectedColumns.filter(column => column.groupByColumn === "Fund Codes").map(column => column.id)
      };
    }
    return JSON.stringify(filters);
  }

  private loadFilteredData(campaignId: number, filterData: string ) {
    const params = {
      campaignId: campaignId,
      search:this.filterValue?.value ?? null, 
      searchFilters: filterData
    };
    this.showLoading = true;
    this.rawData = [];
    this.router.url
    var dataType = this.router.url.split('vortex/')[1]?.toLowerCase();

    // this is for testing, will be removing it once tested
    if(dataType == 'donors/donor-search' || dataType == "donations/add-new-donation")
      dataType = 'donors';

    if(dataType == 'donations/donation-search')
      dataType = 'donations';

    if(dataType == 'expenses/expense-search')
      dataType = 'expenses';

    if (dataType === 'donations/add' || this.searchKeyword)
      dataType= this.selectedSearchArea.name.toLowerCase();
    this.servicesearch.getRawData(dataType, params).subscribe({
      next: (data: any[]) => {
        this.showLoading = false;
        this.rawData = data;
      },
      error: (error) => {
        this.showLoading = false;
        console.error("API Error:", error);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'An error occurred while fetching data.',
          life: 7500
        });
      }
    });
  }
  //#endregion

  //#region onCompareDonors Click
  onCompareDonors() {
    if (this.selectedRows.length === 2) {
      this.servicesearch.emitCompareDonorsSelected(this.selectedRows);
    } else if (this.selectedRows.length !== 2) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please select only two donors to compare.' });
    }
  }
  //#endregion

  //#region Export Options
  //#region onExportToCSV click
  onExportToCSV() {
    const dataToExport = this.resultstable.filteredValue ? this.resultstable.filteredValue : this.rawData;
    const csvData = this.convertToCSV(dataToExport);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const downloadLink = document.createElement('a');
    const url = URL.createObjectURL(blob);
    downloadLink.href = url;
    downloadLink.setAttribute('download', 'exported_data.csv');
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }
  convertToCSV(data: any[]): string {
    if (!data || !data.length) return '';

    const headers = Object.keys(data[0]);
    const csvRows = [];
    csvRows.push(headers.join(','));
    for (const row of data) {
      const values = headers.map(header => {
        const escaped = ('' + row[header]).replace(/"/g, '""');
        return `"${escaped}"`;
      });
      csvRows.push(values.join(','));
    }
    return csvRows.join('\n');
  }
  //#endregion

  //#region onExportToExcel Click
  onExportToExcel() {
    const dataToExport = this.resultstable.filteredValue ? this.resultstable.filteredValue : this.rawData;
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    FileSaver.saveAs(blob, 'data.xlsx');
  }
  //#endregion
  //#endregion

  //#region Get Approvals by ExpenseId
  getApprovalById(requestId: number,event: Event, overlay: any) {
    const params = {
      requestId: requestId,
    };
    this.showLoading = true;
    this.approvalsData = [];
    this.servicesearch.getapprovals(params).subscribe({
      next: (data: any[]) => {
        this.showLoading = false;
        this.approvalsData = data.map(item => ({
          approvedBy: item.approvedBy,
          approvedDate: item.approvedDate ? new Date(item.approvedDate) : null 
        }));
        overlay.toggle(event);
      },
      error: (error) => {
        this.showLoading = false;
        console.error("API Error:", error);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'An error occurred while fetching approval data.',
          life: 7500
        });
      }
    })

  }
  //#endregion
  isSearchActive(): boolean {
    return this.rawData.length >= 1
  }

  isSingleDonorSelected(): boolean {
    return this.selectedRows.length === 1;
  }

  showDonorGivingHistory(rowData: any) {
    this.servicesearch.emitShowDonorGivingHistory(rowData.DonorID);
    this.visibleDonorGivingHistory= true;
  }

  isDisabledCheckbox(modelItem: any): boolean {
    return (this.selectedRows.length === 2 && this.selectedRows.indexOf(modelItem) === -1);
  }

  onSelectDonor(): void {
    if (this.selectedRows.length === 1) {
      this.servicesearch.emitDonorSelected(this.selectedRows[0]);
    } else if (this.selectedRows.length > 1) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please select only one donor.' });
    }
  }

  onAddDonor(): void {
    this.servicesearch.emitAddNewDonorSelected();
  }
  getSeverityAndValue(status: string): { severity: 'success' | 'secondary' | 'info' | 'warning' | 'danger' | 'contrast', value: string } {
    let severity: 'success' | 'secondary' | 'info' | 'warning' | 'danger' | 'contrast' = 'secondary'; // default value 
    let value = status;
    switch (status) {
      case 'Unapproved':
        severity = "danger";
        value = 'Unapproved';
        break;
      case 'Partial':
        severity = 'warning';
        value = 'Partial';
        break;
      case 'Approved':
        severity = 'success';
        value = 'Approved';
        break;
      case 'Paid':
        severity = 'info';
        value = 'Paid';
        break;
      default:
        severity = 'secondary';
        value = 'Unknown';
    }

    return { severity, value };
  }

  isDonor(): boolean {
    return this.router.url.split('vortex/') && this.router.url.split('vortex/')[1]?.split('/')[0]?.toLowerCase().toLowerCase().includes("donor") || this.showInDialog;
  }

  isExpense(): boolean {
    return this.router.url.split('vortex/') && this.router.url.split('vortex/')[1]?.split('/')[0]?.toLowerCase().includes("expense");
  }

  isDonation(): boolean {
    return this.router.url.split('vortex/') && this.router.url.split('vortex/')[1]?.split('/')[0]?.toLowerCase().includes("donation");
  }
  onCancel(): void {
    if( this.showInDialog) {
      this.cancelEvent.emit();
    }
    else{
      this.selectedRows = [];
      this.messageService.add({ severity: 'info', summary: 'Cancelled', detail: 'Selection cancelled.' });
    }
  }
  
  closeDonorGivingHistory(){
    this.visibleDonorGivingHistory= false;
  }

  openDialog(donorId: any){
    this.selectedDonorId = donorId;
    this.showDialog= true;
  }
}
