import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '../../global/config/config.token';
import { AppConfig } from '../../global/config/app.config';
import { Observable } from 'rxjs';
import { UserAdminInviteModel } from '../models/UserInviteModel';

@Injectable({
  providedIn: 'root'
})
export class DonorCardService {

  constructor(private httpClient: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig) { }

  getDonorCardGeneralInfo = (donorId: number): Observable<any> => {
    return this.httpClient.get(`${this.config.apiUrl}donorcard/general-info?donorId=` + donorId, { withCredentials: true });
  }

  getDonorCardContactInfo = (donorId: number): Observable<any> => {
    return this.httpClient.get(`${this.config.apiUrl}donorcard/contact-info?donorId=${donorId}`, { withCredentials: true });
    //return this.httpClient.get(`${this.config.apiUrl}donorcard/contact-info?donorId=${donorId}+campaignId=${campaignId}`, { withCredentials: true });
  }

  getCurrentCycleDonations = (campaignId: number, donorId: number): Observable<any> => {
    return this.httpClient.get(`${this.config.apiUrl}donorcard/donor-current-cycle-donations?campaignId=${campaignId}&donorId=${donorId}`, { withCredentials: true });
  }

  getLifetimeHistory = (campaignId: number, donorId: number): Observable<any> => {
    return this.httpClient.get(`${this.config.apiUrl}donorcard/donor-lifetime-history?campaignId=${campaignId}&donorId=${donorId}`, { withCredentials: true });
  }

  getFundcodesHistory = (campaignId: number, donorId: number): Observable<any> => {
    return this.httpClient.get(`${this.config.apiUrl}donorcard/fundcodes-history?campaignId=${campaignId}&donorId=${donorId}`, { withCredentials: true });
  }

  getDonorCardDonorLetters = (campaignId: number, donorId: number): Observable<any> => {
    return this.httpClient.get(`${this.config.apiUrl}donorcard/donor-communication-letters?campaignId=${campaignId}&donorId=${donorId}`, { withCredentials: true });
  }

  getDonorCardCallsheetsLog = (campaignId: number, donorId: number): Observable<any> => {
    return this.httpClient.get(`${this.config.apiUrl}donorcallsheets/donor-callsheets-log?campaignId=${campaignId}&donorId=${donorId}`, { withCredentials: true });
  }

  getDonorNotes = (donorId: number): Observable<any> => {
    return this.httpClient.get(`${this.config.apiUrl}donorcard/donor-notes?donorId=${donorId}`, { withCredentials: true });
  }

  getDonorFlags = (donorId: number): Observable<any> => {
    return this.httpClient.get(`${this.config.apiUrl}donorcard/donor-flags?donorId=${donorId}`, { withCredentials: true });
  }

  getDonorSalutations = (donorId: number): Observable<any> => {
    return this.httpClient.get(`${this.config.apiUrl}donorcard/donor-salutations?donorId=${donorId}`, { withCredentials: true });
  }

  getDonorSuppression = (donorId: number): Observable<any> => {
    return this.httpClient.get(`${this.config.apiUrl}donorcard/donor-suppression?donorId=${donorId}`, { withCredentials: true });
  }
  
  getDonorRelationships = (donorId: number): Observable<any> => {
    return this.httpClient.get(`${this.config.apiUrl}donorcard/donor-relationships?donorId=${donorId}`, { withCredentials: true });
  }
  
  getDonorForEdit = (donorId: number): Observable<any> => {
    return this.httpClient.get(`${this.config.apiUrl}donor/get-donor?donorId=${donorId}`, { withCredentials: true });
  }
}
