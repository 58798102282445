import { Component, ElementRef, Input, OnInit,NgModule, EventEmitter, Output } from '@angular/core';
import { SearchComponent } from '../../component/search/search.component';
import { filter, tap } from 'rxjs';
import { ServerClientService } from '../../services/data/CampaignService/server-client.service';
import { MainModel } from '../../model/MainModel';
import { JsonPipe } from '@angular/common';
import PagingModel from '../../model/PagingMoel';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchModel } from '../../model/SearchModel';
import { PermissionsCheckingComponent } from '../../component/permissions-checking-component/permissions-checking-component.component';
import { UserPermissionsService } from '../../services/permissions/UserPermissionsService';

@Component({
  selector: 'app-search-page',
  standalone: true,
  imports: [SearchComponent],
  templateUrl: './search.page.component.html',
  styleUrl: './search.component.scss'
})
export class SearchPageComponent extends PermissionsCheckingComponent implements OnInit {
  columnDataResult!: SearchModel;
  dataType: string | null = null;
  @Input() searchPageId: string = 'donors';
  @Input() showInDialog: boolean = false;
  @Input() selectedSearchArea: any = null;
  @Input() searchKeyword: string = '';

  @Output() cancelEvent = new EventEmitter<void>();

  // ================================================================
  pagingModel: PagingModel = new PagingModel();
  // ================================================================

  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    private serverClient: ServerClientService,
    protected model: MainModel, 
    protected ref:ElementRef,
    protected permissions:UserPermissionsService
  ) { 
    super(ref, permissions, router);
    //this.setDataType();
  }

  queryData(pagingModel: PagingModel) {
    console.debug("Search Page:", pagingModel);
  }

  setDataType() {
    if(this.searchKeyword) {
      this.dataType= this.selectedSearchArea.name.toLowerCase();
    }
    else if (!this.showInDialog ) {
      this.dataType = this.router.url.split('vortex/')[1].toLowerCase();
    } 
    else {
      this.dataType = this.searchPageId;
    }
    console.debug(this.dataType);
    // donor search...
    switch(this.dataType){
      case "donors":
        this.pageId = 7;
      break;
      case "donations":
        this.pageId = 10;
        break;
      case "fundraisers":
        this.pageId = 29;
        break;
    }
  }

  override ngOnInit() {
    super.ngOnInit();
    this.setDataType();
    // donation search...
    this.serverClient.getDataTableData(this.dataType || "donors", this.pagingModel, 406).subscribe(
      (data: SearchModel) => {
        this.columnDataResult = data;
        // this.defaultColumns = data.displayColumns;
        // this.selectedColumns = data.displayColumns.filter(x => x.isDefault === true);
        // this.statesResult = data.states;
        // this.pagingModel.data = data;
        // this.pagingModel.count = data.length;
        //this.model.tableData = data.rows.slice(0,20);
      }
    );
  }

  handleCancel(){
    this.cancelEvent.emit(); 
  }
}
