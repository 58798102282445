
export enum Permission {
    None, Read, Write
}

/**
 * Populated by the UI and passed to methods that request data from back end services.
 */
export class SecurityModel {
    isAdminRole:boolean = false;
    features:Map<number, Permission> = new Map(); //here 
    pages:Map<number, Permission> = new Map(); //here 
    featureAndPage:Map<string,Permission> = new Map<string,number>();
}